import firebase from '../../../../utils/firebase/firebase'
import { createUserProfileDocument } from '../../../../utils/firebase/firebase'
import { auth } from '../../../../utils/firebase/firebase'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { validationSchema } from '../../../join/about-you/helpers'
import dayjs from 'dayjs'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import Terms from '../../../join/about-you/Terms'

function RegisterForm() {
  let history = useHistory()

  // submit the registration form
  const handleSubmit = async (values) => {
    const { firstName, lastName, mobile, email, password, dob } = values

    // create user with Firebase Auth using email/password
    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      )

      const authUser = await firebase.auth().currentUser

      //once user is registered/authenticated - create a new user database document
      await createUserProfileDocument(user, {
        firstName,
        lastName,
        mobile,
        dob: dayjs(dob).format('YYYY-MM-DD'),
      })

      //send to account
      if (authUser) {
        history.push('/account')
      } else {
        console.log('No auth user')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      dob: dayjs(),
      password: '',
      confirmPassword: '',
      terms: false,
    },
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box display={'flex'} gap={1} flexDirection={'column'}>
          <Typography variant='h3' mb={2} textAlign={'center'}>
            Get started
          </Typography>
          <TextField
            id='firstName'
            name='firstName'
            label='First Name'
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            disabled={formik.isSubmitting}
            notched={true}
          />
          <TextField
            fullWidth
            id='lastName'
            name='lastName'
            label='Last Name'
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            disabled={formik.isSubmitting}
          />
          <TextField
            fullWidth
            id='mobile'
            name='mobile'
            label='Mobile Number'
            value={formik.values.mobile}
            onChange={formik.handleChange}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={formik.touched.mobile && formik.errors.mobile}
            disabled={formik.isSubmitting}
          />
          <TextField
            fullWidth
            id='email'
            name='email'
            label='Email'
            type='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            disabled={formik.isSubmitting}
          />
          <DatePicker
            format='DD-MM-YYYY'
            id='dob'
            name='dob'
            label='Date of birth'
            value={formik.values.dob}
            onChange={(newValue) => formik.setFieldValue('dob', newValue)}
            disabled={formik.isSubmitting}
          />
          {formik.touched.dob && formik.errors.dob && (
            <Typography
              sx={{
                color: (theme) => theme.palette.error.main,
                fontSize: '12px',
                textAlign: 'left',
              }}
            >
              {formik.errors.dob}
            </Typography>
          )}

          <Box display={'flex'} gap={1}>
            <TextField
              fullWidth
              type='password'
              id='password'
              name='password'
              label='Password'
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              disabled={formik.isSubmitting}
            />
            <TextField
              fullWidth
              type='password'
              id='confirmPassword'
              name='confirmPassword'
              label='Confirm'
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              disabled={formik.isSubmitting}
            />
          </Box>
          <Terms />
          <Box display={'flex'} alignItems={'center'}>
            <Typography
              color={
                formik.touched.terms && formik.errors.terms ? 'red' : 'white'
              }
              fontSize={'12px'}
              sx={{ ml: 1 }}
            >
              I HAVE READ AND AGREE TO THE HEALTH COMMITMENT STATEMENT & DS
              FITNESS'{' '}
              <Link href='/terms' target='_blank'>
                TERMS & CONDITIONS
              </Link>
            </Typography>
            <Checkbox
              id='terms'
              name='terms'
              onChange={(newValue) =>
                formik.setFieldValue('terms', newValue.target.checked)
              }
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              disabled={formik.isSubmitting}
            />
          </Box>
          <Button
            color='primary'
            variant='contained'
            fullWidth
            type='submit'
            disabled={formik.isSubmitting}
            size='large'
          >
            {formik.isSubmitting ? <CircularProgress size={25} /> : 'Continue'}
          </Button>
        </Box>
      </form>
      <Link to='/login'>
        <Button fullWidth size='small' variant='outlined' sx={{ mt: 1 }}>
          Already registered with DS FITNESS?
        </Button>
      </Link>
    </Box>
  )
}

export default RegisterForm
