import React, { useState } from 'react'
import Page from '../../../components/page/Page'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Typography,
  Link,
} from '@mui/material'
import dayjs from 'dayjs'
import { NavLink } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import ConfirmSignupModal from '../components/ConfirmSignupModal'
import useClass from '../useClass'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import FamilySignupModal from '../components/FamilySignupModal'
import RegisterCtaButton from '../components/RegisterCtaButton'

const Session = ({ currentUser }) => {
  const { id } = useParams()
  const [confirmSignup, setConfirmSignup] = useState(false)
  const [confirmFamilySignup, setConfirmFamilySignup] = useState(false)
  const { gymClass, loading, error } = useClass(id)

  const toggleModal = () => {
    currentUser.memberType === 'family' && currentUser.family
      ? setConfirmFamilySignup((ps) => !ps)
      : setConfirmSignup((ps) => !ps)
  }

  const isActive = currentUser
    ? currentUser.status === 'active' || currentUser.status === 'trialing'
    : false

  const isFull =
    !loading && !error
      ? gymClass.class.capacity <= gymClass.class.enrolledMembers.length
      : true

  return (
    <Page loading={loading} error={error}>
      {!loading && !error && (
        <>
          <Box
            sx={{
              background: `url(${gymClass.class.coverImage}), linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3029586834733894) 23%, rgba(0,0,0,0.6559217436974789) 41%, rgba(0,0,0,1) 100%, rgba(0,0,0,0.6559217436974789) 100%);`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundBlendMode: 'multiply',
              height: 300,
              backgroundSize: 'cover',
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <Typography variant='h4' color={'white.main'}>
              {gymClass.class.title}
            </Typography>

            <Typography textTransform={'uppercase'} mb={1}>
              {dayjs(gymClass.startTime.toDate()).format('ddd DD MMM hh:mm a')}{' '}
              - {dayjs(gymClass.endTime.toDate()).format('hh:mm a')} |{' '}
              {dayjs(gymClass.endTime.toDate()).diff(
                dayjs(gymClass.startTime.toDate()),
                'minute'
              )}{' '}
              Minutes
            </Typography>
            <Divider sx={{ mb: 2 }} />
          </Box>
          <Typography maxWidth={'800px'} mb={2}>
            {gymClass.class.description}
          </Typography>
          <Box display={'flex'} gap={1} mb={2} flexWrap={'wrap'} maxWidth={500}>
            {currentUser ? (
              <Button
                size='large'
                variant='contained'
                startIcon={<AddIcon />}
                onClick={toggleModal}
                disabled={!currentUser}
                sx={{ flexGrow: 1 }}
              >
                {isFull ? 'Join waitlist' : 'Sign me up'}
              </Button>
            ) : (
              <RegisterCtaButton />
            )}

            <Link
              to={`/${gymClass.isSession ? 'gym' : 'classes'}`}
              sx={{ flexGrow: 1 }}
              component={NavLink}
            >
              <Button size='large' variant='outlined' fullWidth>
                {`View more gym sessions`}
              </Button>
            </Link>
          </Box>
          {currentUser &&
            currentUser.memberType === 'family' &&
            !currentUser.family && (
              <Alert severity='info'>
                <AlertTitle>Trying to add family to classes?</AlertTitle>
                <Typography fontSize={14}>
                  It looks like you haven't setup your family members yet.{' '}
                  <Link component={NavLink} to={'/account?membership'}>
                    Visit your account
                  </Link>{' '}
                  to add family members then come back to this page to register
                  them on the class.
                </Typography>
              </Alert>
            )}
          {!isActive && currentUser && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography fontWeight={300} fontSize={14}>
                Not a member yet?{' '}
                <Link to='/join' component={NavLink}>
                  <Button
                    variant='text'
                    color='secondary'
                    size='small'
                    sx={{
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {' '}
                    Subscribe to a membership
                  </Button>
                </Link>
              </Typography>
            </>
          )}
          {currentUser && (
            <>
              <ConfirmSignupModal
                isOpen={confirmSignup}
                onClose={toggleModal}
                gymClass={gymClass}
                session={gymClass.isSession}
              />

              {currentUser.family && (
                <FamilySignupModal
                  isOpen={confirmFamilySignup}
                  onClose={toggleModal}
                  gymClass={gymClass}
                />
              )}
            </>
          )}
        </>
      )}
    </Page>
  )
}

const mapStateToProps = ({ user, subscription }) => ({
  currentUser: user.currentUser,
  subscriptionData: subscription.currentSubscription,
})

export default connect(mapStateToProps, null)(Session)
