import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Typography,
} from '@mui/material'
import React from 'react'
import usePtClasses from '../usePtClasses'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import dayjs from 'dayjs'

const PtClasses = ({ currentUser }) => {
  const { classes, loading, error } = usePtClasses(currentUser.id)

  return (
    <Box>
      <Typography variant='h5' color='white.main'>
        PT Classes
      </Typography>
      <Typography variant='body2' mb={2}>
        A list of the upcoming classes you're hosting at DS Fitness
      </Typography>
      {error && (
        <Alert severity='error'>
          <AlertTitle>Error</AlertTitle>Couldn't fetch your classes. Please try
          again. {error}
        </Alert>
      )}
      <Box>
        {classes.length === 0 && (
          <Alert severity='info'>
            <AlertTitle>Oops</AlertTitle>Looks like you're not running any
            classes soon
          </Alert>
        )}
        {!loading &&
          classes.map((c) => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                  sx={{ display: 'flex', gap: 2 }}
                >
                  <Typography color='primary'>
                    {c.class.title}{' '}
                    <Typography variant={'body2'}>
                      {dayjs(c.startTime.toDate()).format(
                        'dddd DD MMM HH:mm a'
                      )}
                    </Typography>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    display={'grid'}
                    gridTemplateColumns={'1fr 1fr'}
                    maxWidth={'sm'}
                  >
                    <Typography>Enrolled</Typography>
                    <Typography variant={'body2'}>
                      {c.class.enrolledMembers.length}/{c.class.capacity}
                    </Typography>
                  </Box>
                  <Box
                    display={'grid'}
                    gridTemplateColumns={'1fr 1fr'}
                    maxWidth={'sm'}
                  >
                    <Typography>Waitlisted</Typography>
                    <Typography variant={'body2'}>
                      {c.class.waitlistMembers.length}
                    </Typography>
                  </Box>
                  <Box
                    display={'grid'}
                    gridTemplateColumns={'1fr 1fr'}
                    maxWidth={'sm'}
                  >
                    <Typography>Location</Typography>
                    <Typography variant={'body2'}>{c.studio.name}</Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
            )
          })}
      </Box>
    </Box>
  )
}

export default PtClasses
