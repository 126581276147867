import firebase from '../../../utils/firebase/firebase'
import dayjs from 'dayjs'
import { firestore } from '../../../utils/firebase/firebase'
import { toast } from 'react-toastify'

export const resourceMap = [
  { resourceId: 'GlO5gKDGW2YMJ3L8Vq87', resourceTitle: 'HALL' },
  { resourceId: 'gilelHF1pzLybKEHmlia', resourceTitle: 'GYM' },
]

export const dateFromDaysPrior = (days) =>
  firebase.firestore.Timestamp.fromDate(dayjs().subtract(days, 'days').toDate())

// Cancel Studio Booking
export const cancelSingleBooking = (bookingId) => {
  const currentTime = firebase.firestore.Timestamp.now()
  firestore
    .collection('bookings')
    .doc(bookingId)
    .update({ status: 'cancelled', cancelledAt: currentTime })
    .then(() => {
      toast.success('CLASS CANCELLED SUCCESSFULLY')
    })
    .catch((error) => {
      toast.error(`Error updating document: ', ${error.message}`)
    })
}

// Cancel Studio Booking
export const reinstateSingleBooking = (bookingId) => {
  firestore
    .collection('bookings')
    .doc(bookingId)
    .update({ status: 'confirmed', cancelledAt: null, isPublished: false })
    .then(() => {
      toast.success('CLASS REINSTATED SUCCESSFULLY')
    })
    .catch((error) => {
      toast.error(`Error updating document: ', ${error.message}`)
    })
}
