import { Chip } from '@mui/material'
import CampaignIcon from '@mui/icons-material/Campaign'
import BlockIcon from '@mui/icons-material/Block'
const LowAvailabilityWarning = ({ capacity, enrolled }) => {
  const remaining = capacity - enrolled
  const soldOut = remaining <= 0
  const low = remaining <= 5

  return (
    <Chip
      label={
        soldOut
          ? 'FULL - Join the waitlist!'
          : `${remaining} of ${capacity} space${remaining > 1 ? 's' : ''} left!`
      }
      sx={{ borderRadius: 1, maxWidth: 200 }}
      color={soldOut ? 'error' : low ? 'warning' : 'secondary'}
      icon={soldOut ? <BlockIcon fontSize='sm' /> : <CampaignIcon />}
    />
  )
}

export default LowAvailabilityWarning
