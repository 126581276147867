import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from '@mui/material'
import dayjs from 'dayjs'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useState } from 'react'
import {
  cancelSingleBooking,
  reinstateSingleBooking,
} from '../../calendar/helpers'
import { useFormik } from 'formik'
import { newFirestore } from '../../../../utils/firebase/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import { toast } from 'react-toastify'
import { promoteUserFromClassWaitlist } from '../../../classes/helpers'

const ClassDetails = ({ gymClass }) => {
  const [copied, setCopied] = useState(false)
  const [updating, setUpdating] = useState(false)

  const toggleUpdating = () => {
    setUpdating((ps) => !ps)
  }

  const classRef = doc(newFirestore, 'bookings', gymClass.id)
  const formik = useFormik({
    initialValues: {
      title: gymClass.class.title,
      description: gymClass.class.description,
      capacity: gymClass.class.capacity,
    },
    onSubmit: async (values) => {
      toggleUpdating()
      try {
        await updateDoc(classRef, {
          class: {
            ...gymClass.class,
            ...values,
          },
        }).then(() => {
          toast.success('Class updated successfully')
        })
        console.log('newCapacity', values.capacity)
        const enrolled = gymClass.class.enrolledMembers.length
        console.log('enrolled', enrolled)
        const newAvailableSpace = values.capacity - enrolled
        console.log('new space avail', newAvailableSpace)
        if (
          newAvailableSpace > 0 &&
          gymClass.class.waitlistMembers.length > 0
        ) {
          for (let i = 0; i < newAvailableSpace; i++) {
            await promoteUserFromClassWaitlist(gymClass)
            toast.success(
              `promoted ${gymClass.class.waitlistMembers[0].firstName} from waitlist`
            )
            console.log('promoted user')
            //remove firts user from waitlist
            gymClass.class.waitlistMembers.shift()
            //fake time delay
            await new Promise((resolve) => setTimeout(resolve, 500))
          }
        }
      } catch (error) {
        toast.error('Error updating class details. Try again.')
      }
    },
  })

  const copyLink = () => {
    navigator.clipboard.writeText(
      `https://www.dsfitnesswales.com/classes/${gymClass.id}`
    )
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, [1000])
  }
  return (
    <Stack gap={2}>
      <Box
        sx={{
          background: `url(${gymClass.class.coverImage}), linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3029586834733894) 23%, rgba(0,0,0,0.6559217436974789) 41%, rgba(0,0,0,1) 100%, rgba(0,0,0,0.6559217436974789) 100%);`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundBlendMode: 'multiply',
          height: 300,
          backgroundSize: 'cover',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      ></Box>
      <Stack gap={2} component='form' onSubmit={formik.handleSubmit}>
        <Box display={'flex'} justifyContent={'flex-end'} gap={1} mb={1}>
          {updating && (
            <Button variant='outlined' onClick={toggleUpdating}>
              Cancel
            </Button>
          )}
          {!updating && (
            <Button
              variant='contained'
              type={updating ? 'submit' : 'button'}
              onClick={() => setUpdating(true)}
            >
              Update
            </Button>
          )}
          {updating && (
            <Button variant='contained' type={'submit'}>
              Submit
            </Button>
          )}
        </Box>
        <Box display={'flex'} flexWrap={'wrap'} gap={2}>
          <TextField
            sx={{ flexGrow: 1 }}
            label='Title   '
            value={updating ? formik.values.title : gymClass.class.title}
            onChange={formik.handleChange}
            id='title'
            name='title'
            disabled={!updating}
          />
          {!gymClass.isSession && (
            <TextField
              sx={{ flexGrow: 1 }}
              label='PT   '
              defaultValue={`${gymClass.user.firstName} ${gymClass.user.lastName}`}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          )}
          <TextField
            sx={{ flexGrow: 1 }}
            label='Date & Time       '
            defaultValue={dayjs(gymClass.startTime.toDate()).format(
              'ddd DD MMM HH:mm A'
            )}
            multiline
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
          {!gymClass.isSession && (
            <TextField
              sx={{ flexGrow: 1 }}
              label='Studio   '
              defaultValue={gymClass.studio.name}
              disabled
            />
          )}
        </Box>
        <TextField
          label='Description       '
          defaultValue={gymClass.class.description}
          multiline
          id='description'
          name='description'
          disabled={!updating}
          value={
            updating ? formik.values.description : gymClass.class.description
          }
          onChange={formik.handleChange}
        />
        <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr'} gap={2}>
          <TextField
            label='Capacity'
            defaultValue={gymClass.class.capacity}
            type='number'
            id='capacity'
            name='capacity'
            onChange={formik.handleChange}
            disabled={!updating}
          />
          <TextField
            label='Enrolled'
            defaultValue={gymClass.class.enrolledMembers.length}
            multiline
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
          <TextField
            label='Waitlisted'
            defaultValue={gymClass.class.waitlistMembers.length}
            multiline
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
        </Box>
      </Stack>
      <FormControl variant='outlined'>
        <InputLabel htmlFor='outlined-adornment-password' disabled>
          {gymClass.isSession
            ? `https://www.dsfitnesswales.com/sessions/${gymClass.id}`
            : `https://www.dsfitnesswales.com/classes/${gymClass.id}`}
        </InputLabel>
        <OutlinedInput
          disabled
          endAdornment={
            <InputAdornment position='end'>
              {copied && 'copied'}
              <IconButton onClick={copyLink} edge='end'>
                {copied ? <CheckCircleIcon /> : <ContentPasteIcon />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {gymClass.status !== 'cancelled' && (
        <Button
          variant='contained'
          color='error'
          sx={{ mt: 1 }}
          fullWidth
          onClick={() => {
            cancelSingleBooking(gymClass.id)
          }}
        >
          Cancel class
        </Button>
      )}
      {gymClass.status === 'cancelled' && (
        <Button
          variant='contained'
          onClick={() => reinstateSingleBooking(gymClass.id)}
        >
          Reinstate class
        </Button>
      )}
    </Stack>
  )
}

export default ClassDetails
