import { Box, Button, Typography } from '@mui/material'
import Page from '../../components/page/Page'
import { Link } from 'react-router-dom'
import Span from '../../components/Span'

const Thanks = () => {
  return (
    <Page>
      <Box
        display='flex'
        flexDirection={'column'}
        gap={3}
        alignItems={'center'}
        my={8}
      >
        <Typography variant='h2' color='white.main' mb={3}>
          Thank <Span>You</Span>.
        </Typography>
        <Box maxWidth='lg'>
          <Typography textAlign='center'>
            Your membership is now activated and your first payment has been
            processed. Please check your email for confirmation of your gym
            subscription.
          </Typography>
        </Box>
        <Typography>We can't wait to see you at DS Fitness.</Typography>
        <Link to='/account'>
          <Button variant='contained'>MY ACCOUNT</Button>
        </Link>
      </Box>
    </Page>
  )
}

export default Thanks
