import { useEffect } from 'react'
import { Switch, Redirect, Route, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { auth } from '../utils/firebase/firebase'
import './App.css'
import Home from '../features/home/Home'
import Packages from '../features/join/packages/Packages.component'
import AboutYou from '../features/join/about-you/AboutYou.component'
import LoginPage from '../features/auth/login/Login'
import Register from '../features/auth/register/Register'
import {
  createUserProfileDocument,
  firestore,
} from '../utils/firebase/firebase'
import { setCurrentUser, setUserSubscription } from '../redux/user/user.actions'
import { setCurrentSubscription } from '../redux/subscription/subscription.actions'
import Account from '../features/account/containers/Account'
import RequestPage from '../features/auth/passwordReset/PasswordReset'
import Loader from '../components/loader/Loader.component'
import Availability from '../features/availability/Availability'
import Confirm from '../features/availability/confirm/Confirm.page'
import Rules from '../features/rules/Rules'
import FaqPage from '../features/faq/FaqPage'
import Thanks from '../features/thanks/Thanks'
import Terms from '../features/terms/Terms'
import Book from '../features/admin/book/Book'
import Unauthorised from '../features/unauthorised/Unauthorised'
import CalendarPage from '../features/admin/calendar/Calendar'
import Dashboard from '../features/admin/dashboard/Dashboard'
import Users from '../features/admin/users/containers/Users'
import UserPage from '../features/admin/users/containers/User'
import { GlobalStyle } from './globalStyle'
import { ThemeProvider } from 'styled-components'
import { theme } from './globalStyle/theme'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Nav from '../components/nav/Nav'
import Classes from '../features/classes/containers/Classes'
import LLanelli from '../features/gyms/llanelli/LLanelli'
import { Fab } from '@mui/material'
import Class from '../features/classes/containers/Class'
import Session from '../features/classes/containers/Session'
import AdminClasses from '../features/admin/classes/containers/Classes'
import AdminClass from '../features/admin/classes/containers/Class'
import Settings from '../features/admin/settings/containers/Settings'
import AdminSessions from '../features/admin/classes/containers/Sessions'

function App({
  setCurrentUser,
  currentUser,
  joinStage,
  subscription,
  setCurrentSubscription,
  loading,
}) {
  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth)

        userRef.onSnapshot((snapShot) => {
          if (!snapShot.exists) {
            console.error('Document does not exist.')
            return
          }

          const userData = snapShot.data()
          const isOneTime = userData.memberType === 'one_time'
          let isExpired = false

          // Check if 'expires' field exists and is a Firestore Timestamp
          if (userData.expires && userData.expires.toDate) {
            const expiresDate = userData.expires.toDate() // Convert Firestore Timestamp to JavaScript Date
            isExpired = expiresDate < new Date() // Compare with current date
          }

          const status =
            isOneTime && isExpired ? 'expired' : userData.status ?? null
          console.log('updating user')

          //update firebase
          firestore.collection('users').doc(snapShot.id).update({
            status,
          })

          // Update Redux store
          setCurrentUser({
            ...currentUser,
            id: snapShot.id,
            ...userData,
            status,
          })
        })
      } else {
        setCurrentUser(null)
        setUserSubscription(null)
      }
    })
    // eslint-disable-next-line
  }, [setCurrentUser])

  useEffect(() => {
    if (currentUser !== null) {
      firestore
        .collection('users')
        .doc(currentUser.id)
        .collection('subscriptions')
        .where('status', 'in', ['trialing', 'active'])
        .onSnapshot(async (snapshot) => {
          if (snapshot.empty) {
            return
          } else {
            const subscriptionData = snapshot.docs[0].data()
            const priceData = (await subscriptionData.price.get()).data()

            setCurrentSubscription({
              priceData: priceData,
              subscriptionData: subscriptionData,
            })
          }
        })
    } else {
    }
    // eslint-disable-next-line
  }, [currentUser])

  return (
    <>
      <ThemeProvider theme={theme}>
        <Nav />
        <GlobalStyle />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/classes' component={Classes} exact />
          <Route path='/gym' render={() => <Classes session />} exact />
          <Route path='/classes/:id' component={Class} />
          <Route path='/sessions/:id' component={Session} />
          <Route
            path='/login'
            exact
            render={() => (currentUser ? <Redirect to='/' /> : <LoginPage />)}
          />
          <Route
            path='/register'
            exact
            render={() =>
              currentUser ? <Redirect to='/account' /> : <Register />
            }
          />
          <Route
            path='/request'
            exact
            render={() => (currentUser ? <Redirect to='/' /> : <RequestPage />)}
          />
          <Route
            path='/join'
            exact
            component={(() => {
              if (joinStage === 1) {
                return ''
              } else if (joinStage === 2) {
                return Packages
              } else if (joinStage === 3) {
                return AboutYou
              } else if (joinStage === 4) {
                return ''
              }
            })()}
          />

          <Route
            path='/availability'
            exact
            component={currentUser ? Availability : LoginPage}
          />
          <Route path='/availability/confirm' exact component={Confirm} />
          <Route path='/about' exact component={LLanelli} />
          <Route path='/rules' exact component={Rules} />
          <Route path='/faq' exact component={FaqPage} />
          <Route path='/thanks' exact component={Thanks} />
          <Route path='/terms' exact component={Terms} />
          {currentUser != null ? (
            <>
              <Route
                path='/account'
                exact
                render={() =>
                  !currentUser ? <Redirect to='/login' /> : <Account />
                }
              />
              {currentUser && currentUser.isAdmin && (
                <>
                  <Route path='/admin/book' exact component={Book} />
                  <Route
                    path='/admin/calendar'
                    exact
                    component={CalendarPage}
                  />
                  <Route path='/admin/classes' exact component={AdminClasses} />
                  <Route
                    path='/admin/sessions'
                    exact
                    component={AdminSessions}
                  />
                  <Route
                    path='/admin/classes/:id'
                    exact
                    component={AdminClass}
                  />
                  <Route path='/admin/dashboard' exact component={Dashboard} />
                  <Route path='/admin/users' exact component={Users} />
                  <Route path='/admin/users/:uid' exact component={UserPage} />
                  <Route path='/admin/settings' exact component={Settings} />
                  <Route exact path='/unauthorised' component={Unauthorised} />
                </>
              )}
            </>
          ) : (
            <Loader />
          )}
        </Switch>
      </ThemeProvider>
      <ToastContainer
        position='top-center'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='dark'
      />
      {currentUser &&
        !currentUser.isAdmin &&
        currentUser.status !== 'active' && (
          <Link to='/join'>
            <Fab
              sx={{
                position: 'fixed',
                bottom: 20,
                right: 20,
                borderRadius: 1,
              }}
              variant='extended'
              color='primary'
            >
              💪 JOIN NOW
            </Fab>
          </Link>
        )}
    </>
  )
}

const mapStateToProps = ({ user, join, subscription, system }) => ({
  currentUser: user.currentUser,
  userSubscription: user.subscription,
  joinStage: join.joinStep,
  subscription: subscription.currentSubscription,
  loading: system.loading,
})

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setUserSubscription: (user) => dispatch(setUserSubscription(user)),
  setCurrentSubscription: (subscription) =>
    dispatch(setCurrentSubscription(subscription)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
