import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/functions'
import 'firebase/analytics'
import 'firebase/compat/storage'
import { getAuth } from 'firebase/auth'

//v9
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'

const prodConfig = {
  apiKey: 'AIzaSyAFBXUe3PNwjn-lVZpxZd_-xPDyLqbtgTg',
  authDomain: 'dsfitness-dc41c.firebaseapp.com',
  projectId: 'dsfitness-dc41c',
  storageBucket: 'dsfitness-dc41c.appspot.com',
  messagingSenderId: '37961371641',
  appId: '1:37961371641:web:5644993b55262c9fbdf728',
  measurementId: 'G-2WZ13GGDZR',
}

const firebaseApp = initializeApp(prodConfig)

firebase.initializeApp(prodConfig)

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return

  const userRef = await firestore.doc(`users/${userAuth.uid}`)
  const snapshot = await userRef.get()

  if (!snapshot.exists) {
    const { email } = userAuth
    const createdAt = new Date()

    try {
      userRef.set({ email, createdAt, ...additionalData, credits: 0 })
    } catch (error) {
      console.log('error creating user', error.message)
    }
  }
  return userRef
}

// Sends user to the Strip hosted payment page
// API - Stripe price key
// user - Firebase Auth UID
export const sendToCheckout = async (API, user) => {
  const docRef = await firestore
    .collection('users')
    .doc(user)
    .collection('checkout_sessions')
    .add({
      price: `${API}`,
      allow_promotion_codes: true,
      success_url: 'https://www.dsfitnesswales.com/thanks',
      cancel_url: window.location.origin,
    })
  // Wait for the CheckoutSession to get attached by the extension

  docRef.onSnapshot((snap) => {
    const { error, url } = snap.data()
    if (error) {
      // Show an error to your customer and
      // inspect your Cloud Function logs in the Firebase console.
      alert(`An error occured: ${error.message}`)
    }
    if (url) {
      // We have a Stripe Checkout URL, let's redirect.
      window.location.assign(url)
    }
  })
}

// Sends user to the Strip hosted payment page
// API - Stripe price key
// user - Firebase Auth UID
export const sendToSingleCheckout = async (API, user) => {
  const docRef = await firestore
    .collection('users')
    .doc(user)
    .collection('checkout_sessions')
    .add({
      mode: 'payment',
      price: `${API}`,
      allow_promotion_codes: true,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    })
  // Wait for the CheckoutSession to get attached by the extension

  docRef.onSnapshot((snap) => {
    const { error, url } = snap.data()
    if (error) {
      // Show an error to your customer and
      // inspect your Cloud Function logs in the Firebase console.
      alert(`An error occured: ${error.message}`)
    }
    if (url) {
      // We have a Stripe Checkout URL, let's redirect.
      window.location.assign(url)
    }
  })
}

export const auth = firebase.auth()
export const newAuth = getAuth(firebaseApp)
export const firestore = firebase.firestore()
export const newFirestore = getFirestore(firebaseApp)
export const storage = firebase.storage()
export const functions = getFunctions(firebaseApp, 'europe-west2')

export const portalRedirect = async () => {
  const functionRef = firebase
    .app()
    .functions('europe-west2')
    .httpsCallable('ext-firestore-stripe-payments-createPortalLink')
  const { data } = await functionRef({ returnUrl: window.location.origin })
  window.location.assign(data.url)
}

export const resetPassword = (email) => {
  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      console.log(' Password reset email sent!')
    })
    .catch((error) => {
      var errorMessage = error.message
      console.log(errorMessage)
    })
}

export default firebase
