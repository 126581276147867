import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#E42522',
      contrastText: '#fff',
    },
    secondary: {
      main: '#9c9c9c',
    },
    background: {
      default: '#000000',
      paper: '#171717',
    },
    white: {
      main: '#fff',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
  },
  shape: {
    borderRadius: 2,
  },
  typography: {
    h1: {
      fontFamily: 'Play',
    },
    h4: {
      fontFamily: 'Play',
    },
    fontFamily: 'Play',
    body1: {
      fontFamily: 'Play',
      color: 'white',
    },
    body2: {
      fontFamily: 'Play',
      color: 'white',
    },
    caption: {
      fontFamily: 'Play',
    },
    button: {
      letterSpacing: '1.2px',
    },
  },
})

export default theme
