import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
  Link,
  CircularProgress,
  Card,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import firebase, {
  auth,
  createUserProfileDocument,
  sendToCheckout,
  sendToSingleCheckout,
} from '../../../utils/firebase/firebase'
import Terms from './Terms'
import { validationSchema } from './helpers'
import { NavLink } from 'react-router-dom'

const SignUpForm = ({ productApi, isOnetime }) => {
  const [error, setError] = useState(null)
  const handleSubmit = async (values, setSubmitting) => {
    setError(null)
    const { firstName, lastName, mobile, email, password, dob } = values

    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      )

      const authUser = await firebase.auth().currentUser

      await createUserProfileDocument(user, {
        firstName,
        lastName,
        mobile,
        dob: dayjs(dob).format('YYYY-MM-DD'),
      })

      if (authUser) {
        isOnetime
          ? sendToSingleCheckout(productApi, authUser.uid)
          : sendToCheckout(productApi, authUser.uid)
      } else {
        setError('Something went wrong')
        setSubmitting(false)
      }
    } catch (error) {
      console.error(error)
      setError(error.message)
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      dob: dayjs(),
      password: '',
      confirmPassword: '',
      terms: false,
    },
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting)
    },
  })
  return (
    <Card
      variant='outlined'
      sx={{
        border: (theme) => `1px solid ${theme.palette.primary.main} `,
        p: 2,
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Typography color={'white'} variant={'h4'} mb={3}>
          Get started
        </Typography>
        <Box display={'flex'} gap={1} flexDirection={'column'}>
          <TextField
            id='firstName'
            name='firstName'
            label='First Name   '
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            disabled={formik.isSubmitting}
            notched={true}
          />
          <TextField
            fullWidth
            id='lastName'
            name='lastName'
            label='Last Name   '
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            disabled={formik.isSubmitting}
          />
          <TextField
            fullWidth
            id='mobile'
            type='string'
            name='mobile'
            label='Mobile Number     '
            value={formik.values.mobile}
            onChange={formik.handleChange}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={formik.touched.mobile && formik.errors.mobile}
            disabled={formik.isSubmitting}
          />
          <TextField
            fullWidth
            id='email'
            name='email'
            label='Email  '
            type='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            disabled={formik.isSubmitting}
          />
          <DatePicker
            format='DD-MM-YYYY'
            id='dob'
            name='dob'
            label='Date of birth   '
            value={formik.values.dob}
            onChange={(newValue) => formik.setFieldValue('dob', newValue)}
            disabled={formik.isSubmitting}
          />
          {formik.touched.dob && formik.errors.dob && (
            <Typography
              sx={{
                color: (theme) => theme.palette.error.main,
                fontSize: '12px',
              }}
            >
              {formik.errors.dob}
            </Typography>
          )}

          <Box display={'flex'} gap={1}>
            <TextField
              fullWidth
              type='password'
              id='password'
              name='password'
              label='Password   '
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              disabled={formik.isSubmitting}
            />
            <TextField
              fullWidth
              type='password'
              id='confirmPassword'
              name='confirmPassword'
              label='Confirm     '
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              disabled={formik.isSubmitting}
            />
          </Box>
          <Terms />
          <Box display={'flex'} alignItems={'center'}>
            <Typography
              color={
                formik.touched.terms && formik.errors.terms ? 'red' : 'white'
              }
              fontSize={'12px'}
              sx={{ ml: 1 }}
            >
              I HAVE READ AND AGREE TO THE HEALTH COMMITMENT STATEMENT & DS
              FITNESS'S{' '}
              <Link href='/terms' target='_blank'>
                TERMS & CONDITIONS
              </Link>
            </Typography>
            <Checkbox
              id='terms'
              name='terms'
              onChange={(newValue) =>
                formik.setFieldValue('terms', newValue.target.checked)
              }
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              disabled={formik.isSubmitting}
            />
          </Box>

          <Button
            color='primary'
            variant='contained'
            fullWidth
            type='submit'
            disabled={formik.isSubmitting}
            size='large'
          >
            {formik.isSubmitting ? <CircularProgress size={25} /> : 'Continue'}
          </Button>
          <Typography
            textAlign={'center'}
            fontSize={'small'}
            sx={{ color: (t) => t.palette.error.main }}
          >
            {error}
          </Typography>
          <Button
            variant='link'
            textAlign={'center'}
            fontSize={'small'}
            sx={{ color: (t) => t.palette.primary.main }}
            to='/login'
            component={NavLink}
          >
            Login instead?
          </Button>
        </Box>
      </form>
    </Card>
  )
}

export default SignUpForm
