import {
  Box,
  Button,
  Card,
  Divider,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import Page from '../../../components/page/Page'
import Span from '../../../components/Span'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import gym1 from '../../../assets/images/ds/ds1.jpeg'
import gym2 from '../../../assets/images/ds/ds2.jpeg'
import NearMeIcon from '@mui/icons-material/NearMe'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

const LLanelli = ({ subscriptionData }) => {
  const mobile = useMediaQuery('(max-width:900px)')
  const images = [gym1, gym2]
  const [pause, setPause] = useState(false)
  const timer = useRef()
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    duration: 1000,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
  })

  useEffect(() => {
    sliderRef.current.addEventListener('mouseover', () => {
      setPause(true)
    })
    sliderRef.current.addEventListener('mouseout', () => {
      setPause(false)
    })
  }, [sliderRef])

  useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 2000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])
  return (
    <Page>
      <Typography variant='h2' color='white.main'>
        Home <Span>sweat</Span>
      </Typography>
      <Typography variant='h2' color='white.main' gutterBottom>
        home.
      </Typography>
      <Box
        display={'grid'}
        gridTemplateColumns={mobile ? '1fr' : '1fr 2fr'}
        gap={1}
      >
        <Card
          variant='outlined'
          sx={{
            border: (theme) => `1px solid ${theme.palette.primary.main}`,
            p: 2,
            borderRadius: 1,
            order: mobile ? 1 : 0,
          }}
        >
          <Typography variant='h3' color='white.main' mb={2}>
            DS Fitness
          </Typography>
          <Divider sx={{ mb: 4 }} />

          <Box display={'flex'} flexDirection='column' gap={5}>
            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
              <Typography color={'primary'} width={'130px'}>
                Gym hours
              </Typography>
              <Box display={'stack'} textAlign={'right'}>
                <Typography variant='body2'>Mon: 5am - 11pm</Typography>
                <Typography variant='body2'>Tue: 5am - 11pm</Typography>
                <Typography variant='body2'>Wed: 5am - 11pm</Typography>
                <Typography variant='body2'>Thu: 5am - 11pm</Typography>
                <Typography variant='body2'>Fri: 5am - 11pm</Typography>
                <Typography variant='body2'>Sat: 5am - 11pm</Typography>
                <Typography variant='body2'>Sun: 5am - 11pm</Typography>
              </Box>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
              <Typography color={'primary'} width={'130px'}>
                Staff hours
              </Typography>
              <Box display={'stack'} textAlign={'right'}>
                <Typography variant='body2'>Mon: 8am - 5pm</Typography>
                <Typography variant='body2'>Tue: 8am - 5pm</Typography>
                <Typography variant='body2'>Wed: 8am - 5pm</Typography>
                <Typography variant='body2'>Thu: 8am - 5pm</Typography>
                <Typography variant='body2'>Fri: 8am - 5pm</Typography>
              </Box>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
              <Typography color={'primary'}>Address</Typography>
              <Box display={'stack'} textAlign={'right'}>
                <Typography variant='body2'>
                  Princess gwenllian centre
                </Typography>
                <Typography variant='body2'>Hillfield villas</Typography>
                <Typography variant='body2'>Kidwelly</Typography>
                <Typography mb={2} variant='body2'>
                  SA17 4UL
                </Typography>
                <a
                  rel='noreferrer'
                  target='_blank'
                  href='https://www.google.com/maps/place/DSFitness/@51.7358738,-4.3159509,17z/data=!3m1!4b1!4m6!3m5!1s0x486ee9c31e989a29:0x62ff95079c08c1e3!8m2!3d51.7358738!4d-4.313376!16s%2Fg%2F11vc70j34d?entry=ttu'
                >
                  <Button variant='outlined'>view on map</Button>
                </a>
              </Box>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
              <Typography color={'primary'}>Contact</Typography>
              <a href='mailto:Declano5@hotmail.co.uk'>
                <Button>
                  <Typography variant='body2'>
                    Declano5@hotmail.co.uk
                  </Typography>
                </Button>
              </a>
            </Box>
          </Box>
        </Card>
        <Box ref={sliderRef} className='keen-slider' sx={{ maxHeight: '80vh' }}>
          {images.map((img, index) => (
            <Box
              className={`keen-slider__slide number-slide${index}`}
              key={img + index}
            >
              <img
                alt='dsfitness'
                src={img}
                width='100%'
                height='100%'
                style={{ objectFit: 'cover' }}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        display={'grid'}
        gridTemplateColumns={mobile ? '1fr' : '2fr 1fr'}
        gap={mobile ? 10 : 30}
      >
        <Box>
          <Typography variant='h3' color='white.main' my={4}>
            Why join us?
          </Typography>
          <Box my={4}>
            <Typography color={'white.main'} variant='h5' mb={2}>
              <Span>No</Span> Contract
            </Typography>
            <Typography>
              Setting the precedent for local gyms; no contract, cancel anytime
              online, in minutes.
            </Typography>
          </Box>
          <Box my={4}>
            <Typography color={'white.main'} variant='h5' mb={2}>
              <Span>No</Span> Joining fee
            </Typography>
            <Typography>
              At DS Fitness, there are no hidden costs and everybody is welcome
              to join.
            </Typography>
          </Box>
          <Box my={4}>
            <Typography color={'white.main'} variant='h5' mb={2}>
              <Span>Unlimited</Span> Classes
            </Typography>
            <Typography>
              Discover our diverse range of engaging, results-driven classes led
              by expert instructors. From calorie-torching HIIT sessions to
              rejuvenating yoga flows, there's something for everyone. Boost
              your strength, flexibility, and endurance while connecting with
              our supportive fitness community.
            </Typography>
          </Box>
          <Box my={4}>
            <Typography color={'white.main'} variant='h5' mb={2}>
              <Span>Pre-booked</Span> Gym sessions
            </Typography>
            <Typography>
              Enhance your gym experience with our pre-booked gym sessions. This
              unique system ensures our gym is never overcrowded, offering you a
              comfortable and spacious environment for your workouts. With the
              ability to see how many people are booked for each session, you
              have the freedom to choose quieter times for a more focused and
              personalised fitness journey.
            </Typography>
          </Box>
          <Box my={4}>
            <Typography color={'white.main'} variant='h5' mb={2}>
              <Span>No</Span> Time restrictions
            </Typography>
            <Typography>
              All of our memberships include full access to the facility. No
              peak or off-peak limitations.
            </Typography>
          </Box>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'start'}
          flexDirection={'column'}
        >
          {!subscriptionData && (
            <>
              <Box
                sx={{
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                  p: 5,
                  borderRadius: 1,
                }}
              >
                <Typography variant='h3' color='white.main'>
                  {' '}
                  What are you waiting for?
                </Typography>
                <Link to='/join'>
                  <Button size='large' sx={{ mt: 2, fontSize: '1.2rem' }}>
                    JOIN NOW
                  </Button>
                </Link>
              </Box>
              <NearMeIcon
                color='white'
                fontSize='large'
                sx={{ mt: -2, ml: -2 }}
              />
            </>
          )}
        </Box>
      </Box>
    </Page>
  )
}

const mapStateToProps = ({ user, subscription }) => ({
  currentUser: user.currentUser,
  subscriptionData: subscription.currentSubscription,
})

export default connect(mapStateToProps, null)(LLanelli)
