import {
  Box,
  Button,
  Container,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material'
import bg from '../../../assets/images/home-bg.jpg'
import bgV from '../../../assets/video/bg-vid.webm'
import bgV_mp4 from '../../../assets/video/bg-vid.mp4'
import Span from '../../../components/Span'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const StyledHeading = styled(Typography)(() => ({
  fontSize: '3rem',
  color: 'white',
  fontWeight: 600,
  lineHeight: 1,
  '@media (min-width:900px)': {
    fontSize: '5rem',
  },
}))

const StyledFeature = styled(Typography)(() => ({
  display: 'flex',
  border: '1px solid #a8a8a8',
  padding: '0.5rem 1rem',
  borderRadius: '.2rem',
  color: 'white',
  gap: 3,
  alignItems: 'center',
  background: '#00000095',
}))

const Header = ({ user, subscriptionData }) => {
  const mobile = useMediaQuery('(max-width:600px)')

  return (
    <Box
      sx={{
        position: 'relative' /* Changed to relative */,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Box
        component='video'
        autoPlay
        loop
        muted
        playsInline
        sx={{
          position: 'fixed',
          right: 0,
          bottom: 0,
          minWidth: '100%',
          minHeight: '100%',
          zIndex: -1 /* To ensure it stays in the background */,
        }}
      >
        <source src={bgV} type='video/webm' />
        <source src={bgV_mp4} type='video/mp4' />
        Your browser does not support the video tag.
      </Box>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: mobile
            ? `rgba(0, 0, 0, ${mobile ? 0.85 : 1})`
            : `linear-gradient(0deg, rgba(0,0,0,.95) 18%, rgba(0,0,0,0.6559217436974789) 35%, rgba(0,0,0,0.6029586834733894) 66%, rgba(0,0,0,.5) 74%),
          url(${bg}) no-repeat center center`,
          zIndex: 1,
        }}
      >
        <Container maxWidth='xl'>
          <StyledHeading
            variant='h1'
            sx={{ mt: mobile ? 20 : 35, maxWidth: 950 }}
          >
            Join a<Span> community </Span>
            that <Span> drives you </Span> to do better.
          </StyledHeading>
          <Box display={'flex'} gap={2} mt={2}>
            <StyledFeature noWrap variant='h6'>
              <Span>No</Span> Contract{' '}
            </StyledFeature>
            <StyledFeature noWrap variant='h6'>
              <Span>No</Span> Joining Fee{' '}
            </StyledFeature>
          </Box>
          {mobile && (
            <Link to='/join'>
              <Button variant='contained' fullWidth sx={{ mt: 2 }}>
                Join Now
              </Button>
            </Link>
          )}
        </Container>
      </Box>
    </Box>
  )
}

const mapStateToProps = ({ user, subscription }) => ({
  currentUser: user.currentUser,
  subscriptionData: subscription.currentSubscription,
})

export default connect(mapStateToProps, null)(Header)
