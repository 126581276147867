import { Box, Button, Card, Divider, Typography } from '@mui/material'
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined'
import CurrencyPoundOutlinedIcon from '@mui/icons-material/CurrencyPoundOutlined'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import { convertCycle } from './helpers'

const MembershipSummary = ({ product, onGoBack }) => {
  return (
    <Card
      variant='outlined'
      sx={{
        border: (theme) => `1px solid ${theme.palette.primary.main} `,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
      }}
    >
      <Box>
        <Typography variant='h4' mb={3}>
          Membership summary
        </Typography>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant='h6'>{product.description}</Typography>
          <Typography variant='h6'>
            £{product.priceMain}.{product.priceDecimal} {product.cycle}
          </Typography>
        </Box>
        <Divider sx={{ mb: 2, mt: 1 }} />
        <Box display={'grid'} gap={1}>
          {product.attributes.map((attribute) => (
            <Box key={attribute} display={'flex'} alignItems={'center'} gap={1}>
              <AdjustOutlinedIcon fontSize='small' />
              <Typography>{attribute}</Typography>
            </Box>
          ))}
        </Box>
        <Typography variant='h6' mt={4}>
          Payment schedule
        </Typography>
        <Divider sx={{ mb: 2, mt: 1 }} />
        <PayScheduleWithoutTrial
          product={product}
          isOneTime={product.type === 'once'}
        />
      </Box>

      <Button
        variant='outlined'
        size='large'
        fullWidth
        onClick={onGoBack}
        sx={{ mt: 2 }}
      >
        Back to select membership
      </Button>
    </Card>
  )
}

export default MembershipSummary

// const PayScheduleWithTrial = ({ product }) => {
//   const tomorrow = dayjs().add(25, 'hour').format('hh:mm a on ddd D MMM')
//   return (
//     <Box display={'grid'} gap={1.5}>
//       <Box display={'flex'} alignItems={'start'} gap={1}>
//         <MoneyOffCsredOutlinedIcon fontSize='small' />
//         <Typography>
//           Today pay{' '}
//           <Typography component={'span'} fontWeight={'bold'} color={'primary'}>
//             £0.00
//           </Typography>{' '}
//         </Typography>
//       </Box>
//       <Box display={'flex'} alignItems={'start'} gap={1}>
//         <FitnessCenterOutlinedIcon fontSize='small' />
//         <Typography>
//           Trial the gym for 24 hours. Not suitable? Cancel online and you won't
//           be charged.
//         </Typography>
//       </Box>
//       <Box display={'flex'} alignItems={'start'} gap={1}>
//         <CurrencyPoundOutlinedIcon fontSize='small' />
//         <Typography>
//           Your first payment of{' '}
//           <Typography component={'span'} fontWeight={'bold'} color={'primary'}>
//             £{product.priceMain}.{product.priceDecimal}{' '}
//           </Typography>
//           will be debited around {tomorrow}
//         </Typography>
//       </Box>
//       <Box display={'flex'} alignItems={'start'} gap={1}>
//         <CurrencyPoundOutlinedIcon fontSize='small' />
//         <Typography>
//           Then pay{' '}
//           <Typography component={'span'} fontWeight={'bold'} color={'primary'}>
//             £{product.priceMain}.{product.priceDecimal}
//           </Typography>{' '}
//           at the same time every {convertCycle(product.cycle)}
//         </Typography>
//       </Box>
//       <Box display={'flex'} alignItems={'start'} gap={1}>
//         <SentimentVeryDissatisfiedIcon />
//         <Typography>
//           Ever unhappy? No hard feelings! Cancel online in minutes and any
//           future charges are stopped immediately.
//         </Typography>
//       </Box>
//     </Box>
//   )
// }

const PayScheduleWithoutTrial = ({ product, isOneTime }) => {
  return (
    <Box display={'grid'} gap={1.5}>
      <Box display={'flex'} alignItems={'start'} gap={1}>
        <CurrencyPoundOutlinedIcon fontSize='small' />
        <Typography>
          Pay{' '}
          <Typography component={'span'} fontWeight={'bold'} color={'primary'}>
            £{product.priceMain}.{product.priceDecimal}{' '}
          </Typography>
          today
        </Typography>
      </Box>
      {!isOneTime && (
        <>
          <Box display={'flex'} alignItems={'start'} gap={1}>
            <CurrencyPoundOutlinedIcon fontSize='small' />
            <Typography>
              Then{' '}
              <Typography
                component={'span'}
                fontWeight={'bold'}
                color={'primary'}
              >
                £{product.priceMain}.{product.priceDecimal}
              </Typography>{' '}
              at the same time every {convertCycle(product.cycle)}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'start'} gap={1}>
            <SentimentVeryDissatisfiedIcon />
            <Typography>
              Ever unhappy? No hard feelings! Cancel online in minutes and any
              future charges are stopped immediately.
            </Typography>
          </Box>
        </>
      )}
    </Box>
  )
}
