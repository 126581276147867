import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import useClass from '../../../classes/useClass'
import { useParams } from 'react-router-dom'
import Loader from '../../../../components/Loader'
import EnrolledTable from '../components/EnrolledTable'
import { toast } from 'react-toastify'
import WaitlistTable from '../components/WaitlistTable'
import dayjs from 'dayjs'
import ClassDetails from '../components/ClassDetails'
import { togglePublishedClass } from '../helpers'
import AdminPage from '../../../../components/page/AdminPage'
import { CSVLink } from 'react-csv'

const Class = () => {
  const { id } = useParams()
  const { gymClass, loading, error } = useClass(id)
  const [tabs, setTabs] = useState(0)
  const [published, setPublished] = useState(false)

  useEffect(() => {
    gymClass && setPublished(gymClass.isPublished)
  }, [gymClass])

  const togglePublished = (event) => {
    try {
      togglePublishedClass(id, published)
      setPublished(event.target.checked)
      toast.success(
        `Class is now ${!published ? 'published' : 'back in draft'}`
      )
    } catch (error) {
      toast.error('error updating class status')
    }
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    toast.error(error)
    return <></>
  }

  const enrolled = gymClass.class.enrolledMembers
  const waitlisted = gymClass.class.waitlistMembers
  const handleTabChange = (event, newValue) => {
    setTabs(newValue)
  }

  const exportData = enrolled
    .map((member) => ({ ...member, status: 'enrolled' }))
    .concat(waitlisted.map((member) => ({ ...member, status: 'waitlisted' })))

  return (
    <AdminPage>
      <Typography variant='h4' color={'white.main'} textAlign={'center'} mb={1}>
        {gymClass.class.title}
      </Typography>
      <Typography textAlign={'center'} mb={1}>
        {dayjs(gymClass.startTime.toDate()).format('ddd DD MMM HH:mm')}
      </Typography>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Tabs value={tabs} onChange={handleTabChange}>
          <Tab label='Details' />
          <Tab label='Enrolled' />
          <Tab label='Waitlisted' />
        </Tabs>

        <Box>
          <Box
            component={CSVLink}
            data={exportData}
            filename={`${gymClass.class.title}.csv`}
            target='_blank'
            sx={{
              padding: '2px 10px',
              fontFamily: 'Montserrat',
              '&:hover': {
                color: '#d30606',
              },
            }}
          >
            Export
          </Box>
          {gymClass.status !== 'cancelled' ? (
            <FormControlLabel
              value='start'
              control={
                <Switch checked={published} onChange={togglePublished} />
              }
              label='Published'
              labelPlacement='start'
            />
          ) : (
            <Chip
              sx={{ borderRadius: 1, textTransform: 'uppercase' }}
              label='cancelled'
              color='error'
            />
          )}
        </Box>
      </Box>

      <Divider sx={{ mb: 1.5 }} />
      {tabs === 0 && <ClassDetails gymClass={gymClass} />}
      {tabs === 1 && (
        <EnrolledTable
          enrolled={enrolled}
          gymClass={gymClass}
          isFull={
            gymClass.class.capacity <= gymClass.class.enrolledMembers.length
          }
        />
      )}
      {tabs === 2 && (
        <WaitlistTable waitlisted={waitlisted} gymClass={gymClass} />
      )}
    </AdminPage>
  )
}

export default Class
