import React, { useState } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import {
  setCurrentJoinStep,
  setSelectedProduct,
} from '../../../redux/join/join.actions'
import {
  sendToCheckout,
  sendToSingleCheckout,
} from '../../../utils/firebase/firebase'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from '@mui/material'
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined'

function Package({
  membership,
  currentUser,
  setCurrentJoinStep,
  setSelectedProduct,
}) {
  const { price, cycle, stripeAPI, type, attributes, description } = membership
  const priceMain = price.slice(0, -2)
  const priceDecimal = price.slice(-2)
  const [submitting, setSubmitting] = useState(false)

  const handleOnClick = () => {
    setSubmitting(true)
    if (!currentUser) {
      setCurrentJoinStep(3)
      setSelectedProduct({
        priceMain,
        priceDecimal,
        cycle,
        stripeAPI,
        type,
        description,
        attributes,
      })
      console.log('No user - register & checkout')
    } else {
      setSelectedProduct({
        priceMain,
        priceDecimal,
        cycle,
        stripeAPI,
        type,
        description,
        attributes,
      })

      type === 'once'
        ? sendToSingleCheckout(stripeAPI, currentUser.id)
        : sendToCheckout(stripeAPI, currentUser.id)
    }
  }

  return (
    <Paper
      elevation={3}
      variant='outlined'
      sx={{
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant='h5'>{description}</Typography>
          <Typography variant='h4'>
            £{priceMain}.{priceDecimal}
          </Typography>
        </Box>
        <Divider sx={{ mt: 1 }} />
        <Box display={'grid'} gap={1} mt={1}>
          {attributes.map((attribute) => (
            <Box key={attribute} display={'flex'} alignItems={'center'} gap={1}>
              <AdjustOutlinedIcon fontSize='small' />
              <Typography>{attribute}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Button
        fullWidth
        variant='contained'
        sx={{ mt: 2 }}
        size='large'
        onClick={handleOnClick}
      >
        {submitting ? (
          <CircularProgress size={25} color='secondary' />
        ) : (
          `Join for £${priceMain}.${priceDecimal} ${cycle}`
        )}
      </Button>
    </Paper>
  )
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  setCurrentJoinStep: (joinStep) => dispatch(setCurrentJoinStep(joinStep)),
  setSelectedProduct: (product) => dispatch(setSelectedProduct(product)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Package)
