import { Box, Container } from '@mui/material'
import Loader from '../../components/Loader'
import PageError from '../../components/PageError'

const Page = ({
  children,
  container = true,
  loading = false,
  error = false,
  hideFooter,
}) => {
  if (loading) {
    return <Loader />
  }

  if (error) {
    return (
      <Container maxWidth='xl' sx={{ py: 2, minHeight: 'calc(100vh - 340px)' }}>
        <PageError message={error} />
      </Container>
    )
  }

  return (
    <Box sx={{ background: (theme) => theme.palette.background.default }}>
      {container ? (
        <Container
          maxWidth='xl'
          sx={{ py: 2, minHeight: 'calc(100vh - 340px)' }}
        >
          {children}
        </Container>
      ) : (
        <Box>{children}</Box>
      )}
    </Box>
  )
}

export default Page
