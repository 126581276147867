import { Box, Paper, Typography } from '@mui/material'

const Terms = () => {
  return (
    <Paper
      sx={{
        background: '#FDFCF1',
        padding: 1,
        color: (theme) => theme.palette.secondary.dark,
        height: 200,
        overflow: 'scroll',
      }}
    >
      <Typography variant='h5'>Health Commitment Statement</Typography>

      <Typography variant='body2' color={'black'}>
        Your health is your responsibility. The management and staff at DS
        FITNESS are dedicated to helping you take every opportunity to enjoy the
        facilities that we offer. With this in mind, we have carefully
        considered what we can reasonably expect of each other.
      </Typography>
      <Typography variant='h6' mt={2}>
        Our commitment to you:
      </Typography>
      <Box display={'grid'} gap={1}>
        <Typography variant='body2' color={'black'}>
          We will respect your personal decisions, and allow you to make your
          own decisions about what exercise you can carry out. However, we ask
          you not to exercise beyond what you consider to be your own abilities.
        </Typography>
        <Typography variant='body2' color={'black'}>
          We will make every reasonable effort to make sure that our equipment
          and facilities are in a safe condition for you to use and enjoy.
        </Typography>
        <Typography variant='body2' color={'black'}>
          If you tell us that you have a disability which puts you at a
          substantial disadvantage in accessing our equipment and facilities, we
          will consider what adjustments, if any, are reasonable for us to make.
        </Typography>
      </Box>
      <Typography variant='h6' mt={2}>
        Your commitment to us:{' '}
      </Typography>
      <Box display={'grid'} gap={1}>
        <Typography variant='body2' color={'black'}>
          You should not exercise beyond your own abilities. If you know or are
          concerned that you have a medical condition which might interfere with
          you exercising safely, before you use our equipment and facilities you
          should get advice from a relevant medical professional and follow that
          advice.
        </Typography>
        <Typography variant='body2' color={'black'}>
          You should make yourself aware of any rules and instructions,
          including warning notices, and follow them. Exercise carries its own
          risks. You should not carry out any activities which you have been
          told are not suitable for you.
        </Typography>
        <Typography variant='body2' color={'black'}>
          You should let a medical professional know immediately if you feel ill
          when using our equipment or facilities.
        </Typography>
        <Typography variant='body2' color={'black'}>
          This statement is for guidance only. It is not a legally binding
          agreement between you and us and does not create any obligations which
          you or we must meet.
        </Typography>
      </Box>
      <Typography variant='h6' mt={2}>
        Health Declaration Form - COVID-19:
      </Typography>
      <Box display={'grid'} gap={1}>
        <Typography variant='body2' color={'black'}>
          I confirm that within the fourteen (14) days immediately preceding the
          date of this Health Declaration Form, neither I nor anyone I live
          with, nor any persons associated with my membership (i.e. joint
          members, juniors, guests) have:
        </Typography>
        <Typography variant='body2' color={'black'}>
          Tested positive or presumptively positive for the Coronavirus
          (COVID-19) or been identified as a potential carrier of the COVID-19
          virus;
        </Typography>
        <Typography variant='body2' color={'black'}>
          Experienced any symptoms commonly associated with the COVID-19; nor
          Been in direct contact with or in the immediate vicinity of any person
          I know had or has (or is or was carrying) COVID-19; or any person who
          has been identified as a potential carrier of COVID-19
        </Typography>
        <Typography variant='body2' color={'black'}>
          I confirm that I will inform DS FITNESS if any of the circumstances
          relating to my personal health or the health of an associated member
          changes or if I am informed that I (or an associated member) have been
          in direct contact with or in the immediate vicinity of any person I
          know to be carrying the COVID-19 virus, even it has only come to my
          knowledge after the event.
        </Typography>
        <Typography variant='body2' color={'black'}>
          I confirm that neither I nor anyone associated with my membership in
          any way whatsoever (family or household members) will attend a DS
          FITNESS facility if I or they feel unwell or are displaying any
          symptoms related to COVID-19 subsequent to signing this Health
          Declaration Form.
        </Typography>
        <Typography variant='body2' color={'black'}>
          Symptoms of the COVID-19 virus as described by the NHS are: fever or
          high temperature, new and continuous cough, new loss of taste or smell
          and shortness of breath or difficulty breathing.
        </Typography>
      </Box>
    </Paper>
  )
}

export default Terms
