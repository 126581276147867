import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'

const emails = ['username@gmail.com', 'user02@gmail.com']

function RegisterCtaDialog(props) {
  const { onClose, selectedValue, open } = props

  const handleClose = () => {
    onClose(selectedValue)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Register with a membership?</DialogTitle>
      <Divider />
      <Box sx={{ padding: 2.5 }}>
        <Typography variant='body1'>
          You can register for a monthly membership or pay-as-you-go. Membership
          includes unlimited gym sessions and classes. The pay-as-you-go option
          is £5/session.
        </Typography>
        <Typography variant='body1' mt={1}>
          You can upgrade to a monthly membership at any time.
        </Typography>
        <Stack gap={0.5} mt={1}>
          <Link to='/join'>
            <Button variant='contained' fullWidth>
              Register with monthly membership
            </Button>
          </Link>
          <Link to='/register'>
            <Button variant='contained' fullWidth>
              Register & Pay-As-You-Go
            </Button>
          </Link>
        </Stack>
      </Box>
    </Dialog>
  )
}

RegisterCtaDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
}

export default function RegisterCtaButton() {
  const [open, setOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState(emails[1])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
    setSelectedValue(value)
  }

  return (
    <>
      <Button
        variant='contained'
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
      >
        Register to join
      </Button>
      <RegisterCtaDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </>
  )
}
