import './styles.scss'
import { Fade } from 'react-awesome-reveal'
import Request from './components/PasswordResetForm/Request.component'

function PasswordReset() {
  return (
    <div className='requestPage'>
      <Fade direction='down'>
        <div className='requestPage__container'>
          <h1 className='requestPage__title'>Password Reset </h1>
          <p className='requestPage__subTitle'>
            You signed up with DS Fitness by creating a username and password.
            Enter your email to request a password reset email.
          </p>
          <Request dark />
        </div>
      </Fade>
    </div>
  )
}

export default PasswordReset
