import { useEffect, useState } from 'react'
import firebase, { firestore } from '../../utils/firebase/firebase'
import { dateFromDaysPrior } from '../admin/calendar/helpers'

const useClasses = (
  includePast = false,
  includeCancelled = false,
  includeDraft = false,
  isSession = false
) => {
  const [classes, setClasses] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    const classData = []
    const now = firebase.firestore.Timestamp.fromDate(new Date())
    const PAST_DAYS_TO_DISPLAY = 7

    firestore
      .collection('bookings')
      .where('isClass', '==', true)
      .where('isSession', '==', isSession)
      .where(
        'startTime',
        '>',
        includePast ? dateFromDaysPrior(PAST_DAYS_TO_DISPLAY) : now
      )
      .orderBy('startTime', 'asc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          const isCancelled = doc.data().status === 'cancelled'
          const isDraft = !doc.data().isPublished

          if (isDraft) {
            if (isCancelled) {
              includeCancelled &&
                classData.push({
                  ...data,
                  id: doc.id,
                })
            } else {
              includeDraft &&
                classData.push({
                  ...data,
                  id: doc.id,
                })
            }
          } else {
            if (isCancelled) {
              includeCancelled &&
                classData.push({
                  ...data,
                  id: doc.id,
                })
            } else {
              classData.push({
                ...data,
                id: doc.id,
              })
            }
          }
        })
        setClasses(classData)
        setLoading(false)
      })
      .catch((error) => {
        setError(error.message)
        setLoading(false)
      })
  }, [includePast, includeCancelled, includeDraft, isSession])

  return { classes, loading, error }
}

export default useClasses
