import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Page from '../../../components/page/Page'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import Span from '../../../components/Span'
import Memberships from '../components/Memberships'
import Studios from '../components/Studios'
import Classes from '../components/Classes'
import PersonalDetails from '../components/PersonalDetails'
import PersonalTraining from '../components/PersonalTraining'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { friendlyCurrentUser } from '../../classes/helpers'

const Account = ({ currentUser, subscriptionData }) => {
  const [tabs, setTabs] = useState(0)
  const mobile = useMediaQuery('(max-width:1000px)')
  const { search } = useLocation()

  useEffect(() => {
    switch (search) {
      case '?membership':
        setTabs(1)
        break
      case '?classes':
        setTabs(2)
        break
      case '?pt':
        setTabs(3)
        break
      default:
        setTabs(0)
    }
  }, [search])

  return (
    <Page>
      <Box
        sx={{
          position: 'sticky',
          top: -20,
          backgroundColor: (t) => t.palette.background.default,
          zIndex: 99,
        }}
      >
        <Typography
          textTransform={'uppercase'}
          variant={`${mobile ? 'h4' : 'h2'}`}
          color={'white.main'}
        >
          WELCOME BACK, <Span>{currentUser.firstName}</Span>.
        </Typography>
        <Tabs
          value={tabs}
          variant='scrollable'
          scrollButtons='auto'
          allowScrollButtonsMobile
          sx={{
            '& .MuiTabs-scrollButtons': {
              color: (t) => t.palette.primary.main,
              background: (t) => t.palette.background.paper,
            },
          }}
        >
          <Tab label='Personal details' component={Link} to={'/account'} />
          <Tab
            label='Membership'
            disabled={!subscriptionData}
            component={Link}
            to={'/account?membership'}
          />
          <Tab label='Bookings' component={Link} to={'/account?classes'} />
          {currentUser.isPt && (
            <Tab
              label='Personal training'
              component={Link}
              to={'/account?pt'}
            />
          )}
        </Tabs>
        <Divider />
      </Box>
      <Box mt={2}>
        {search === '' && <PersonalDetails currentUser={currentUser} />}
        {search === '?membership' && (
          <Memberships
            currentUser={currentUser}
            subscriptionData={subscriptionData}
          />
        )}
        {search === '?studios' && <Studios currentUser={currentUser} />}
        {search === '?classes' && (
          <Classes user={friendlyCurrentUser(currentUser)} />
        )}
        {search === '?pt' && <PersonalTraining currentUser={currentUser} />}
      </Box>
      {!subscriptionData && (
        <>
          <Divider sx={{ my: 2 }} />
          <Alert sx={{ mb: 2 }} severity='info'>
            <AlertTitle>JOIN DS FITNESS TODAY</AlertTitle>
            <Typography component={'div'}>
              It looks like you don't have a membership at DS FITNESS yet! What
              are you waiting for?
              <Typography component={'span'} fontWeight={'300'}>
                <ul>
                  <li>- Unlimited access to the gym</li>
                  <li>- No Contract. Cancel anytime</li>
                  <li>- Unlimited free member classes</li>
                </ul>
              </Typography>
            </Typography>

            <Button
              variant='contained'
              sx={{ mt: 1 }}
              component={Link}
              to={'/join'}
            >
              View packages and join now
            </Button>
          </Alert>
        </>
      )}
    </Page>
  )
}

const mapStateToProps = ({ user, join, subscription }) => ({
  currentUser: user.currentUser,
  joinStage: join.joinStep,
  subscriptionData: subscription.currentSubscription,
})
export default connect(mapStateToProps, null)(Account)
