import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { firestore } from '../../../utils/firebase/firebase'
import { toast } from 'react-toastify'
import { dateFromDaysPrior } from './helpers'

const useSubscribedEvents = () => {
  const [events, setEvents] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = firestore
      .collection('bookings')
      .where('startTime', '>', dateFromDaysPrior('14'))
      .onSnapshot(
        (snapshot) => {
          if (snapshot.empty) {
            toast.error('No bookings found, please try again.')
          }
          const events = []
          snapshot.forEach((doc) => {
            const {
              isRecurring,
              isPublished,
              isClass,
              class: gymClass,
              recurringCount,
              recurringDescription,
              startTime,
              endTime,
              user,
              recurringWeek,
              bookingDetails,
              studio,
              createdAt,
              channel,
              status,
            } = doc.data()

            const friendlyRecurringCount =
              isRecurring && recurringCount > 0 ? recurringCount : '-'
            const classTitle =
              isClass &&
              `${!isPublished ? '✏️' : ''} ${user.firstName ?? ''} ${
                user.lastName ?? ''
              }  ${user.firstName ? '|' : ''}  ${gymClass.title} ${
                isRecurring
                  ? `🔄 ${recurringWeek} / ${friendlyRecurringCount}`
                  : ''
              }`
            const bookingTitle = ` ${user.firstName}  ${user.lastName} ${
              isRecurring
                ? `🔄 ${recurringWeek} / ${friendlyRecurringCount} ${
                    recurringDescription ?? ''
                  }`
                : ''
            } `
            const eventData = {
              start: startTime.toDate(),
              end: endTime
                ? endTime.toDate()
                : dayjs(startTime.toDate()).add(1, 'hour').toDate(),
              title: isClass ? classTitle : bookingTitle,
              credits: bookingDetails.credits,
              description: recurringDescription ?? '',
              recurringCount: friendlyRecurringCount,
              recurringWeek,
              resourceId: studio.id,
              studio: studio.name,
              eventId: doc.id,
              firstName: user.firstName,
              lastName: user.lastName,
              uid: user.id,
              createdAt: createdAt ? createdAt.toDate() : 'unknown',
              channel,
              gymClass,
              isClass,
              isRecurring: isRecurring ?? false,
              isPublished: isPublished ?? false,
            }
            if (status === 'confirmed') {
              events.push(eventData)
            }
          })
          setEvents(events)
          setLoading(false)
        },
        (error) => {
          toast.error('Error retrieving bookings: ', error)
        }
      )

    return () => {
      unsubscribe()
    }
  }, [])
  return { events, loading }
}

export default useSubscribedEvents
