import React, { useState } from 'react'

import { auth } from '../../../../../utils/firebase/firebase'
import Loader from '../../../../../components/loader/Loader.component'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { setLoading } from '../../../../../redux/system/system.actions'
import { TextField, Typography, Button, Stack } from '@mui/material'
function LoginForm({ setLoading }) {
  const [error, setError] = useState('')
  const [state, setState] = useState({
    email: '',
    password: '',
    loading: false,
  })

  const handleChange = (e) => {
    const { value, name } = e.target
    setState({ ...state, [name]: value })
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    setError('')

    await auth
      .signInWithEmailAndPassword(state.email, state.password)
      .then((userCredential) => {})
      .catch((error) => {
        setError(error.message)
      })

    setLoading(false)
  }

  return (
    <>
      <Stack
        component='form'
        onSubmit={handleSubmit}
        gap={1}
        sx={{ width: '100%' }}
      >
        <TextField
          label='Email'
          variant='outlined'
          type='email'
          name='email'
          value={state.email}
          onChange={handleChange}
          fullWidth
        >
          Email Address
        </TextField>
        <TextField
          label='Password'
          variant='outlined'
          type='password'
          name='password'
          value={state.password}
          onChange={handleChange}
          fullWidth
        >
          Password
        </TextField>
        <Button
          disabled={!state.email || !state.password}
          variant='contained'
          type='submit'
          fullWidth
          size='large'
        >
          {!state.loading ? 'LOGIN' : <Loader inline />}
        </Button>
      </Stack>
      <Typography
        variant='body2'
        sx={{ color: (t) => t.palette.primary.light, mt: 1 }}
      >
        {error && 'invalid email or password'}
      </Typography>
      <Link to='/request'>
        <Button variant='text' color='white'>
          forgotten your password?
        </Button>
      </Link>
      <Typography mb={1}>- OR - </Typography>
      <Link to='/register'>
        <Button
          variant='contained'
          className='login__register'
          fullWidth
          size='large'
        >
          Register
        </Button>
      </Link>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (system) => dispatch(setLoading(system)),
})

export default connect(null, mapDispatchToProps)(LoginForm)
