import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { UserContainer, Section } from '../user/User.styles'
import AdminNotes from '../user/components/adminNotes/AdminNotes'
import { firestore } from '../../../../utils/firebase/firebase'
import { Box, Divider, Tab, Tabs } from '@mui/material'
import Classes from '../user/components/Classes'
import AdminPage from '../../../../components/page/AdminPage'
import useUser from '../../../account/useUser'
import PersonalDetails from '../user/components/PersonalDetails'
import Loader from '../../../../components/Loader'
import { toast } from 'react-toastify'
import TabPanel from '../../../../components/TabPanel'
import Memberships from '../components/Memberships'

function UserPage() {
  const params = useParams()
  const [subscriptions, setSubscriptions] = useState([])
  const [tabs, setTabs] = useState(0)
  const { user, loading, error } = useUser(params.uid)

  const handleTabChange = (event, newValue) => {
    setTabs(newValue)
  }

  useEffect(() => {
    const getSubscriptions = async () => {
      console.log('getting subscription...')
      const subscriptions = []
      firestore
        .collection(`users/${params.uid}/subscriptions`)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            subscriptions.push({ data: doc.data(), id: doc.id })
          })
          setSubscriptions(subscriptions)
          console.log('sub: ', subscriptions)
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })
    }
    getSubscriptions()
    //eslint-disable-next-line
  }, [params.uid])

  if (loading) {
    return <Loader />
  }

  if (error) {
    toast.error('Error loading user')
    return
  }

  return (
    <AdminPage>
      <Box>
        <Tabs value={tabs} onChange={handleTabChange}>
          <Tab label='Personal Details' />
          <Tab label='Admin Notes' />
          <Tab label='Membership details' />
          {/* <Tab label='Bookings' /> */}
          <Tab label='Bookings' />
          {/* <Tab label='Guest Pass' />
          {user.memberType === 'family' && <Tab label='Family' />} */}
        </Tabs>
        <Divider />
        <UserContainer>
          <TabPanel value={tabs} index={0}>
            <PersonalDetails user={user} />
          </TabPanel>
          <TabPanel value={tabs} index={1} sx={{ width: '100%' }}>
            <AdminNotes user={user} />
          </TabPanel>
          <TabPanel value={tabs} index={2} sx={{ width: '100%' }}>
            <Section>
              <Memberships subscriptions={subscriptions} />
            </Section>
          </TabPanel>
          {/* <TabPanel value={tabs} index={3}>
            <Section>
              <Heading>
                <Typography variant='h5'>Bookings</Typography>
              </Heading>
              <UserBookingsCard
                getUserBookings={getUserBookings}
                bookings={bookings}
                bookingType='future'
              />
              <UserBookingsCard
                getUserBookings={getUserBookings}
                bookingType='past'
                bookings={bookings}
              />
              <UserBookingsCard
                getUserBookings={getUserBookings}
                bookingType='recurring'
                bookings={bookings}
              />
            </Section>
          </TabPanel> */}
          <TabPanel value={tabs} index={3}>
            <Classes user={user} />
          </TabPanel>
          {/* <TabPanel value={tabs} index={4}>
            {user && <GuestPass user={user} />}
          </TabPanel>
          <TabPanel value={tabs} index={5}>
            <ManageFamily family={user.family} />
          </TabPanel> */}
        </UserContainer>
      </Box>
    </AdminPage>
  )
}

export default UserPage
