import { connect } from 'react-redux'
import { setCurrentJoinStep } from '../../../redux/join/join.actions'
import { Fade } from 'react-awesome-reveal'
import SignUpForm from './SignUpForm'
import { Box, useMediaQuery } from '@mui/material'
import MembershipSummary from './MembershipSummary'
import Page from '../../../components/page/Page'

function AboutYou({ setCurrentJoinStep, selectedProduct, currentUser }) {
  const mobile = useMediaQuery('(max-width:1000px)')

  const handleGoBack = () => {
    setCurrentJoinStep(2)
  }

  console.log('selectedProduct', selectedProduct)

  return (
    <Fade>
      <Page>
        <Box
          display={'grid'}
          gridTemplateColumns={mobile ? '1fr' : '1fr 1fr'}
          gap={1}
          justifyContent={'center'}
        >
          <MembershipSummary
            product={selectedProduct}
            onGoBack={handleGoBack}
          />
          <SignUpForm
            productApi={selectedProduct.stripeAPI}
            isOnetime={selectedProduct.type === 'once'}
          />
        </Box>
      </Page>
    </Fade>
  )
}
const mapDispatchToProps = (dispatch) => ({
  setCurrentJoinStep: (join) => dispatch(setCurrentJoinStep(join)),
})

const mapStateToProps = ({ join, user }) => ({
  selectedProduct: join.selectedProduct,
  currentUser: user.currentUser,
})

export default connect(mapStateToProps, mapDispatchToProps)(AboutYou)
