import { useState, useEffect } from 'react'
import { firestore } from '../../../utils/firebase/firebase'
import AdminPage from '../../../components/page/AdminPage'
import { Box } from '@mui/material'
import dayjs from 'dayjs'
import TotalMembers from './components/TotalMembers'
import MemberAgeGroup from './components/MemberAgeGroup'
import NewMembers from './components/NewMembers'
import MemberPlan from './components/MemberPlan'

function Dashboard() {
  const [users, setUsers] = useState([])
  const [registered, setRegistered] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const getData = async () => {
    // Get user Data
    const userArray = []
    const registeredArray = []
    await firestore
      .collection('users')
      .where('status', 'in', ['trialing', 'active', 'past_due'])
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const user = doc.data()
          user.id = doc.id
          user.createdAt =
            doc.data().createdAt && dayjs(doc.data().createdAt.toDate())
          userArray.push(user)
        })
        setUsers(userArray)
        console.group(users)
        return
      })
      .catch((error) => {
        console.log('Error getting documents: ', error)
      })

    await firestore
      .collection('users')
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const user = doc.data()
          user.id = doc.id
          user.createdAt =
            doc.data().createdAt && dayjs(doc.data().createdAt.toDate())
          registeredArray.push(user)
        })
        setRegistered(registeredArray)
        setIsLoading(false)
        return
      })
      .catch((error) => {
        console.log('Error getting documents: ', error)
      })
  }

  useEffect(() => {
    getData()
    //eslint-disable-next-line
  }, [])

  const newMemberData = users
    .filter((u) => {
      return u.createdAt > dayjs().subtract(1, 'week')
    })
    .sort((a, b) => {
      return dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1
    })

  const newRegisteredData = registered
    .filter((u) => {
      return u.createdAt > dayjs().subtract(1, 'week')
    })
    .sort((a, b) => {
      return dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1
    })

  return (
    <AdminPage loading={isLoading}>
      {users && (
        <>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(35rem, 1fr))',
              maxWidth: '100vw',
              gap: 1,
            }}
          >
            <TotalMembers
              users={registered}
              newUsers={newRegisteredData}
              title={'Total Registered'}
            />
            <TotalMembers
              users={users}
              newUsers={newMemberData}
              title={'Total Memberships'}
            />
            <MemberPlan users={users} />
            <MemberAgeGroup users={registered} />
          </Box>
          <NewMembers newUsers={newMemberData} />
        </>
      )}
    </AdminPage>
  )
}

export default Dashboard
