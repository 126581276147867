import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Typography,
} from '@mui/material'
import React from 'react'
import ClassCard from './ClassCard'
import useUserWaitlistClasses from '../../../../account/useUserWaitlistClasses'
import Loader from '../../../../../components/Loader'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const WaitlistClasses = ({ user, family }) => {
  return (
    <Box>
      <Typography mb={2} variant='h5' color='white.main'>
        WAITLIST BOOKINGS
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography textTransform={'capitalize'}>
            {user.firstName} {user.lastName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <WaiitlistComp user={user} />
        </AccordionDetails>
      </Accordion>
      {family.map((member, i) => (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography textTransform={'capitalize'}>
              {member.firstName} {member.lastName}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <WaiitlistComp
              user={{
                dob: member.dob,
                firstName: member.firstName,
                lastName: member.lastName,
                id: member.id,
                mobile: user.mobile,
                primeId: user.id,
                email: user.email,
              }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}

export default WaitlistClasses

const WaiitlistComp = ({ user }) => {
  const { waitlistClasses, loading, error } = useUserWaitlistClasses(user.id)

  if (loading) {
    return <Loader />
  }
  return (
    <>
      {error ? (
        <Alert severity='error'>
          <AlertTitle>Oops</AlertTitle>
          Error fetching waitlist classes for {user.firstName} {error}
        </Alert>
      ) : (
        <>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(18rem, 1fr))',
              gap: 2,
            }}
          >
            {!loading &&
              waitlistClasses.map((c, i) => (
                <ClassCard gymClass={c} user={user} key={c.id + i} />
              ))}
          </Box>
        </>
      )}
    </>
  )
}
