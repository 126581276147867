import { setStudioId } from '../../redux/system/system.actions'
import { connect } from 'react-redux'
import Page from '../../components/page/Page'
import Header from './components/Header'

function Home() {
  return (
    <Page container={false} hideFooter>
      <Header />
    </Page>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setStudioId: (system) => dispatch(setStudioId(system)),
})

export default connect(null, mapDispatchToProps)(Home)
