import React, { useState } from 'react'
import firebase, {
  firestore,
  storage,
} from '../../../../utils/firebase/firebase'
import dayjs from 'dayjs'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { DateField, TimeField } from '@mui/x-date-pickers'
import { Form, Formik } from 'formik'
import TagsField from './TagsField'
import { createBooking } from '../../../../utils/helpers'

function isDaylightSavingTime(d) {
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset()
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset()
  return Math.max(jan, jul) !== d.getTimezoneOffset()
}

// Adjust for daylight savings time
//eslint-disable-next-line
const getDSTAdjustedDate = (d) => {
  const newDate = new Date(d)
  return isDaylightSavingTime(newDate) ? dayjs(d).add(1, 'hour') : d
}

function ClassForm({ currentUser, gym = false }) {
  const [duration, setDuration] = useState('unlimited')
  const [users, setUsers] = useState([])
  const [image, setImage] = useState()
  const [imageProgress, setImageProgress] = useState()
  // const [studios, setStudios] = useState([])
  // const [studioOpen, setStudioOpen] = useState(false)
  // const loadingStudios = studioOpen && studios.length === 0
  const [userOpen, setUserOpen] = useState(false)
  const loadingUsers = userOpen && users.length === 0

  // const getStudios = () => {
  //   const studioData = []
  //   firestore
  //     .collection('studios')
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //         const data = doc.data()
  //         studioData.push({ data, id: doc.id, label: data.shortName })
  //       })
  //       setStudios(studioData)
  //     })
  //     .catch((error) => {
  //       console.log('Error getting documents: ', error)
  //     })
  // }

  const getUsers = () => {
    const userData = []
    //	Get users from firestore
    firestore
      .collection('users')
      .where('isPt', '==', true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          userData.push({
            ...data,
            id: doc.id,
            label: ` ${data.firstName} ${data.lastName} 
        ${data.email}`,
          })
        })
        setUsers(userData)
      })
      .catch((error) => {
        toast.error(error.message)
      })
  }

  const handleSubmit = async (booking) => {
    try {
      booking.isSession = gym
      await createBooking(booking).then((r) =>
        toast.success(`Class created successfully, ${r}`)
      )
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <Formik
      initialValues={{
        bookingDetails: { disabled: false, price: 0, credits: 0 },
        status: 'confirmed',
        createdAt: firebase.firestore.Timestamp.now(),
        channel: currentUser.firstName,
        recurringWeek: 0,
        recurringCount: -1,
        isRecurring: false,
        isClass: true,
        isSession: gym,
        isPublished: false,
        recurringDescription: '',
        user: {},
        studio: {},
        date: '',
        startTime: '',
        endTime: '',
        description: '',
        class: {
          capacity: 0,
          enrolledMembers: [],
          waitlistMembers: [],
          title: gym ? '1 Hour gym session' : '',
          description: 'Book a 1 hour gym session.',
          tags: [],
          coverImage: '',
        },
      }}
      onSubmit={(v, { setSubmitting }) => {
        const startTime = new Date(v.date)
        startTime.setHours(new Date(v.startTime).getHours())
        startTime.setMinutes(new Date(v.startTime).getMinutes())
        startTime.setSeconds(0)
        startTime.setMilliseconds(0)

        const endTime = new Date(v.date)
        endTime.setHours(new Date(v.endTime).getHours())
        endTime.setMinutes(new Date(v.endTime).getMinutes())
        endTime.setSeconds(0)
        endTime.setMilliseconds(0)

        handleSubmit({
          ...v,
          startTime,
          endTime,
          studio: !!gym
            ? { capacity: 12, id: 'gilelHF1pzLybKEHmlia', name: 'gym' }
            : { capacity: 12, id: 'GlO5gKDGW2YMJ3L8Vq87', name: 'hall' },
          date: dayjs(v.date).toString(),
        }).then(() => {
          setSubmitting(false)
        })
      }}
    >
      {(props) => {
        const uploadImage = () => {
          var storageRef = storage.ref()
          var uploadTask = storageRef
            .child('class_images/' + image.name)
            .put(image)
          // Listen for state changes, errors, and completion of the upload.
          uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              setImageProgress(progress)
            },
            (error) => {
              console.log(error)
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                props.setFieldValue('class.coverImage', downloadURL)
              })
            }
          )
        }

        return (
          <Form>
            <Box display={'grid'} gap={2} sx={{ mt: 2 }}>
              {!gym && (
                <Autocomplete
                  id='user'
                  name='user'
                  required
                  onChange={(e, value) =>
                    value &&
                    props.setFieldValue('user', {
                      email: value.email,
                      firstName: value.firstName,
                      lastName: value.lastName,
                      mobile: value.mobile,
                      id: value.id,
                      brand: value.brand ?? null,
                    })
                  }
                  open={userOpen}
                  onOpen={() => {
                    setUserOpen(true)
                    getUsers()
                  }}
                  onClose={() => {
                    setUserOpen(false)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => option.label}
                  options={users}
                  loading={loadingUsers}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={'PT'}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingUsers ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              )}

              {/* {!gym && (
                <Autocomplete
                  id='studio'
                  name='studio'
                  onChange={(e, value) =>
                    value &&
                    props.setFieldValue('studio', {
                      capacity: value.data.capacity,
                      id: value.id,
                      name: value.data.name,
                    })
                  }
                  open={studioOpen}
                  onOpen={() => {
                    setStudioOpen(true)
                    getStudios()
                  }}
                  onClose={() => {
                    setStudioOpen(false)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => option.label}
                  options={studios}
                  loading={loadingStudios}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label='Studio'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingStudios ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              )} */}
              <DateField
                fullWidth
                label='Date'
                onChange={(value, context) =>
                  !context.validationError && props.setFieldValue('date', value)
                }
                format='DD MMM YY'
                disablePast
                required
              />
              <TimeField
                fullWidth
                ampm={false}
                label='Start time'
                onChange={(value, context) =>
                  !context.validationError &&
                  props.setFieldValue('startTime', value)
                }
                required
                helperText='24 hour clock'
              />
              <TimeField
                fullWidth
                ampm={false}
                helperText='24 hour clock'
                label='End time'
                onChange={(value, context) =>
                  !context.validationError &&
                  props.setFieldValue('endTime', value)
                }
                required
              />
              <TextField
                label='Capacity'
                variant='outlined'
                type='number'
                id='capacity'
                required
                placeholder='The max number of members per session'
                name='capacity'
                onChange={(e) => {
                  const capacity = parseInt(e.target.value)
                  e && props.setFieldValue('class.capacity', capacity)
                }}
              />
              {!gym && (
                <>
                  <TextField
                    label='Title'
                    variant='outlined'
                    placeholder='Class title'
                    onChange={(e) =>
                      props.setFieldValue('class.title', e.target.value)
                    }
                  />
                  <TextField
                    label='Description'
                    variant='outlined'
                    placeholder='A description of the class'
                    multiline
                    maxRows={4}
                    onChange={(e) =>
                      props.setFieldValue('class.description', e.target.value)
                    }
                  />
                </>
              )}
              {!gym && <TagsField {...props} />}
              <Typography>Cover Image</Typography>
              {imageProgress === 100 && (
                <Box
                  component='img'
                  sx={{
                    height: 233,
                    maxHeight: { xs: 233, md: 167 },
                  }}
                  alt='cover image.'
                  src={props.values.class.coverImage}
                />
              )}
              <Box display={'flex'} gap={2}>
                <Input
                  required
                  type='file'
                  onChange={(e) => {
                    setImage(e.target.files[0])
                  }}
                />
                <Button
                  variant='contained'
                  onClick={uploadImage}
                  disabled={!image}
                >
                  {imageProgress < 100 ? 'loading' : 'Upload'}
                </Button>
              </Box>

              <Box display={'flex'} gap={2}>
                <FormGroup style={{ color: 'white' }}>
                  <FormControlLabel
                    control={<Switch id='isRecurring' name='isRecurring' />}
                    label='Recurring'
                    onChange={(e) => {
                      const populate = () => {
                        props.setFieldValue('isRecurring', e.target.checked)
                        props.setFieldValue('recurringWeek', 1)
                      }
                      const reset = () => {
                        props.setFieldValue('isRecurring', e.target.checked)
                        props.setFieldValue('recurringWeek', 0)
                        props.setFieldValue('recurringDescription', '')
                        props.setFieldValue('recurringCount', -1)
                      }

                      e.target.checked ? populate() : reset()
                    }}
                  />
                </FormGroup>
              </Box>
              {props.values.isRecurring && (
                <Box display={'grid'} gap={1} mt={1}>
                  <Typography color={'white.main'} variant='h5'>
                    Additional information
                  </Typography>
                  <FormControl>
                    <FormLabel>
                      How long should the recurring class continue?
                    </FormLabel>
                    <RadioGroup
                      row
                      name='duration'
                      sx={{ color: 'white' }}
                      value={duration}
                    >
                      <FormControlLabel
                        value='unlimited'
                        control={<Radio />}
                        label='Until cancelled'
                        onChange={(e) => setDuration(e.target.value)}
                      />
                      <FormControlLabel
                        value='block'
                        control={<Radio />}
                        label='For a block of weeks'
                        onChange={(e) => setDuration(e.target.value)}
                      />
                    </RadioGroup>
                  </FormControl>
                  {duration === 'block' && (
                    <TextField
                      label='Weeks'
                      variant='outlined'
                      type='number'
                      placeholder={`Number of weeks this ${
                        props.values.isClass ? 'class' : 'booking'
                      } should run for`}
                      onChange={(e) =>
                        props.setFieldValue(
                          'recurringCount',
                          parseInt(e.target.value)
                        )
                      }
                    />
                  )}
                </Box>
              )}
              <Button
                size='large'
                variant='contained'
                type='submit'
                disabled={props.isSubmitting || !props.values.class.coverImage}
              >
                {props.isSubmitting ? (
                  <CircularProgress size={'1.5rem'} />
                ) : (
                  'Book'
                )}
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
})

export default connect(mapStateToProps, null)(ClassForm)
