import { Box, Typography } from '@mui/material'
import { BarChart } from '@mui/x-charts/BarChart'

const MemberPlan = ({ users }) => {
  const activeGym = users.filter(
    (user) => user.memberType === 'gym' && user.status === 'active'
  )

  const activeGymClass = users.filter(
    (user) => user.memberType === 'gymClass' && user.status === 'active'
  )
  const overdueGym = users.filter(
    (user) => user.memberType === 'gym' && user.status === 'past_due'
  )

  const overdueGymClass = users.filter(
    (user) => user.memberType === 'gymClass' && user.status === 'past_due'
  )

  const seriesA = {
    data: [activeGym.length, activeGymClass.length],
    label: 'Active plan',
  }
  const seriesB = {
    data: [overdueGym.length, overdueGymClass.length],
    label: 'Overdue plan',
  }

  const xAxis = {
    scaleType: 'band',
    data: ['Gym only', 'Gym & Classes'],
  }

  return (
    <Box
      sx={{
        border: (t) => `1px solid ${t.palette.secondary.main}`,
        padding: 3,
        borderRadius: '10px',
      }}
    >
      <Typography>Memberships</Typography>
      <BarChart
        width={600}
        height={300}
        colors={(t) => ['#55f896', '#ed6a6a']}
        xAxis={[
          {
            ...xAxis,
            tickLabelStyle: {
              angle: 45,
              dominantBaseline: 'hanging',
              textAnchor: 'start',
            },
            labelStyle: {
              transform: 'translateY(15px)',
            },
          },
        ]}
        series={[
          { ...seriesA, stack: 'total' },
          { ...seriesB, stack: 'total' },
        ]}
      />
    </Box>
  )
}

export default MemberPlan
