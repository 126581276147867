import { useState, useEffect } from 'react'
import { firestore } from '../../../../utils/firebase/firebase'
import { toast } from 'react-toastify'
import AdminPage from '../../../../components/page/AdminPage'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box, Chip } from '@mui/material'
import { useHistory } from 'react-router-dom'

function Users() {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  useEffect(() => {
    try {
      const localUsers = JSON.parse(localStorage.getItem('users'))
      if (localUsers) {
        setUsers(localUsers)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
    // Get user Data
    const userArray = []
    firestore
      .collection('users')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const id = doc.id
          userArray.push({ ...doc.data(), id: id })
        })
        setUsers(userArray)
        setLoading(false)
        try {
          localStorage.setItem('users', JSON.stringify(userArray))
        } catch (error) {
          console.log(error)
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }, [])

  const columns = [
    {
      field: 'firstName',
      headerName: 'First name',
      flex: 2,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      flex: 2,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 3,
    },
    {
      field: 'mobile',
      headerName: 'Phone',
      flex: 2,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        const status = params.value
        if (status === 'active') {
          return (
            <Chip
              label='Active'
              color='success'
              size='small'
              sx={{ p: 1, borderRadius: 2 }}
            />
          )
        } else if (status === 'past_due' || status === 'unpaid') {
          return (
            <Chip
              label='Overdue'
              color='error'
              size='small'
              sx={{ p: 1, borderRadius: 2 }}
            />
          )
        } else if (status === 'trialing') {
          return (
            <Chip
              label='Trial'
              color='info'
              size='small'
              sx={{ p: 1, borderRadius: 2 }}
            />
          )
        } else {
          return status ? (
            <Chip
              label={status}
              size='small'
              sx={{ p: 1, textTransform: 'capitalize', borderRadius: 2 }}
            />
          ) : (
            <></>
          )
        }
      },
    },
  ]

  return (
    <AdminPage loading={loading}>
      <Box sx={{ width: '100%', height: 'calc(100vh - 150px)' }}>
        <DataGrid
          rows={users}
          columns={columns}
          disableRowSelectionOnClick
          autoPageSize
          loading={loading}
          slots={{ toolbar: GridToolbar }}
          density='compact'
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          columnHeaderHeight={70}
          onRowClick={(row) => history.push(`/admin/users/${row.id}`)}
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: 'common.white',
            '& .MuiDataGrid-row:hover': {
              color: 'primary.main',
            },
            '.MuiDataGrid-columnHeaders': {
              backgroundColor: 'common.white',
              color: 'black',
              borderRadius: 0,
              '.MuiDataGrid-columnHeaderTitle': {
                fontSize: '1rem',
                letterSpacing: 0.5,
                textTransform: 'uppercase',
              },
              '.MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '.MuiDataGrid-sortIcon': {
                color: 'black',
              },
              '.MuiDataGrid-menuIconButton': { color: 'black' },
            },
          }}
        />
      </Box>
    </AdminPage>
  )
}

export default Users
