import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useFormik } from 'formik'
import EditIcon from '@mui/icons-material/Edit'
import { useState } from 'react'
import dayjs from 'dayjs'
import { FAMILY_CHANGE_POLICY, updateFamily } from '../helpers'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'

const ManageFamily = ({ currentUser }) => {
  const mobile = useMediaQuery('(max-width:1000px)')
  const [edit, setEdit] = useState(false)
  const [adult2, setAdult2] = useState(
    'family' in currentUser && 'adult2' in currentUser.family
  )
  const [child1, setChild1] = useState(
    'family' in currentUser && 'child1' in currentUser.family
  )
  const [child2, setChild2] = useState(
    'family' in currentUser && 'child2' in currentUser.family
  )

  const canEdit = true
  const toggleEdit = () => {
    edit && !currentUser.family.adult2 && setAdult2(false)
    edit && !currentUser.family.child1 && setChild1(false)
    edit && !currentUser.family.child2 && setChild2(false)
    edit && formik.setFieldValue('family', currentUser.family)
    setEdit((ps) => !ps)
  }

  const addAdult2 = () => {
    formik.setFieldValue('family.adult2', {
      firstName: '',
      lastName: '',
      id: uuidv4(),
      dob: dayjs().format('YYYY-MM-DD'),
    })
    setAdult2(true)
  }

  const addChild1 = () => {
    formik.setFieldValue('family.child1', {
      firstName: '',
      lastName: '',
      id: uuidv4(),
      dob: dayjs().format('YYYY-MM-DD'),
    })
    setChild1(true)
  }

  const addChild2 = () => {
    formik.setFieldValue('family.child2', {
      firstName: '',
      lastName: '',
      id: uuidv4(),
      dob: dayjs().format('YYYY-MM-DD'),
    })
    setChild2(true)
  }

  const formik = useFormik({
    initialValues: {
      family: currentUser.family ? currentUser.family : {},
    },

    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        updateFamily(currentUser.id, values.family)
        toast.success('Family successfully updated.')
        setEdit(false)
        setSubmitting(false)
      } catch (error) {
        toast.error('Unable to update family. Please try again later.')
        setSubmitting(false)
      }
    },
  })

  console.log(formik.values)
  return (
    <Box>
      <Box display={'flex'} alignItems='start' gap={1}>
        <Typography mb={2} variant='h5' color='white.main'>
          Family Members
        </Typography>
        <Tooltip
          title={`${
            !canEdit
              ? `You must wait ${FAMILY_CHANGE_POLICY} days before updating family members again.`
              : ''
          }`}
        >
          <Box>
            <IconButton
              size='small'
              onClick={toggleEdit}
              disabled={edit || !canEdit}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Stack gap={2}>
          {adult2 ? (
            <Box
              display={'grid'}
              gap={1}
              gridTemplateColumns={mobile ? '1fr' : '1fr 2fr 2fr 2fr'}
            >
              <TextField disabled variant='outlined' defaultValue={'Adult 2'} />
              <TextField
                id={`family.adult2.firstName`}
                name={`family.adult2.firstName`}
                label='First name    '
                variant='outlined'
                disabled={!edit || formik.isSubmitting}
                value={formik.values.family.adult2.firstName}
                onChange={formik.handleChange}
              />
              <TextField
                id={`family.adult2.lastName`}
                name={`family.adult2.lastName`}
                label='Last name    '
                variant='outlined'
                disabled={!edit || formik.isSubmitting}
                value={formik.values.family.adult2.lastName}
                onChange={formik.handleChange}
              />
              <DatePicker
                format='DD-MM-YYYY'
                id='family.adult2.dob'
                name='family.adult2.dob'
                label='Date of birth   '
                size='small'
                disabled={!edit || formik.isSubmitting}
                value={dayjs(formik.values.family.adult2.dob)}
                onChange={(newValue) =>
                  formik.setFieldValue(
                    `family.adult2.dob`,
                    dayjs(newValue).format('YYYY-MM-DD')
                  )
                }
              />
            </Box>
          ) : (
            edit && (
              <Button
                variant='outlined'
                onClick={addAdult2}
                disabled={formik.isSubmitting}
              >
                Add adult 2
              </Button>
            )
          )}
          {child1 ? (
            <Box
              display={'grid'}
              gap={1}
              gridTemplateColumns={mobile ? '1fr' : '1fr 2fr 2fr 2fr'}
            >
              <TextField
                disabled
                variant='outlined'
                defaultValue={'Child 1'}
                sx={{ flexGrow: 1 }}
              />
              <TextField
                id={`family.child1.firstName`}
                name={`family.child1.firstName`}
                label='First name    '
                variant='outlined'
                disabled={!edit || formik.isSubmitting}
                value={formik.values.family.child1.firstName}
                onChange={formik.handleChange}
                sx={{ flexGrow: 1 }}
              />
              <TextField
                id={`family.child1.lastName`}
                name={`family.child1.lastName`}
                label='Last name    '
                variant='outlined'
                disabled={!edit || formik.isSubmitting}
                value={formik.values.family.child1.lastName}
                onChange={formik.handleChange}
                sx={{ flexGrow: 1 }}
              />
              <DatePicker
                format='DD-MM-YYYY'
                id='family.child1.dob'
                name='family.child1.dob'
                label='Date of birth   '
                size='small'
                disabled={!edit || formik.isSubmitting}
                value={dayjs(formik.values.family.child1.dob)}
                sx={{ flexGrow: 1 }}
                onChange={(newValue) =>
                  formik.setFieldValue(
                    `family.child1.dob`,
                    dayjs(newValue).format('YYYY-MM-DD')
                  )
                }
              />
            </Box>
          ) : (
            edit && (
              <Button
                variant='outlined'
                onClick={addChild1}
                disabled={formik.isSubmitting}
              >
                Add Child 1
              </Button>
            )
          )}
          {child2 ? (
            <Box
              display={'grid'}
              gap={1}
              gridTemplateColumns={mobile ? '1fr' : '1fr 2fr 2fr 2fr'}
            >
              <TextField
                disabled
                variant='outlined'
                defaultValue={'Child 2'}
                sx={{ flexGrow: 1 }}
              />
              <TextField
                id={`family.child2.firstName`}
                name={`family.child2.firstName`}
                label='First name    '
                variant='outlined'
                disabled={!edit || formik.isSubmitting}
                value={formik.values.family.child2.firstName}
                onChange={formik.handleChange}
                sx={{ flexGrow: 1 }}
              />
              <TextField
                id={`family.child2.lastName`}
                name={`family.child2.lastName`}
                label='Last name    '
                variant='outlined'
                disabled={!edit || formik.isSubmitting}
                value={formik.values.family.child2.lastName}
                onChange={formik.handleChange}
                sx={{ flexGrow: 1 }}
              />
              <DatePicker
                format='DD-MM-YYYY'
                id='family.child2.dob'
                name='family.child2.dob'
                label='Date of birth   '
                size='small'
                disabled={!edit || formik.isSubmitting}
                value={dayjs(formik.values.family.child2.dob)}
                sx={{ flexGrow: 1 }}
                onChange={(newValue) =>
                  formik.setFieldValue(
                    `family.child2.dob`,
                    dayjs(newValue).format('YYYY-MM-DD')
                  )
                }
              />
            </Box>
          ) : (
            edit && (
              <Button
                variant='outlined'
                onClick={addChild2}
                disabled={formik.isSubmitting}
              >
                Add Child 2
              </Button>
            )
          )}

          <Box>
            {edit && (
              <Box display={'flex'} gap={1}>
                <Button
                  type='submit'
                  variant='contained'
                  disabled={formik.isSubmitting}
                  fullWidth={mobile}
                >
                  Submit changes
                </Button>
                <Button
                  onClick={toggleEdit}
                  variant='outlined'
                  disabled={formik.isSubmitting}
                  fullWidth={mobile}
                >
                  Cancel changes
                </Button>
              </Box>
            )}
          </Box>
        </Stack>
      </form>
    </Box>
  )
}

export default ManageFamily
