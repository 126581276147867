import { doc, updateDoc } from 'firebase/firestore'
import firebase, {
  firestore,
  newFirestore,
} from '../../utils/firebase/firebase'
import { getAuth, updateEmail } from 'firebase/auth'
import { toast } from 'react-toastify'

export const sortAndFilterBookings = (bookings) => {
  const sortedBookings = bookings.sort(function (a, b) {
    return a.startTime - b.startTime
  })
  const futureBookings = sortedBookings.filter(
    (booking) => booking.startTime > firebase.firestore.Timestamp.now()
  )
  return futureBookings
}

export const cancelStudioBooking = (user, bookingId, creditsPaid) => {
  const currentTime = firebase.firestore.Timestamp.now()
  const newBalance = user.credits + creditsPaid

  firestore
    .collection('bookings')
    .doc(bookingId)
    .update({ status: 'cancelled', cancelledAt: currentTime })

    .then(() => {
      console.log('Document successfully updated to status: cancelled')

      // Increase user credit balance
      const creditRef = firestore.collection('users').doc(user.id)
      creditRef.update({
        credits: newBalance,
      })
    })
    .catch((error) => {
      console.error('Error updating document: ', error)
    })
}

// The number of hours prior to a stuidio booking commencing that a user can cancel
export const BOOKING_CANX_POLICY = 0.5

// The number of minutes prior to a class commencing that a user can cancel
export const CLASS_CANX_POLICY = 30

//The number of days a use must wait to change family members after an update
export const FAMILY_CHANGE_POLICY = 30

export const formatPrice = (price) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(price / 100)
}

export const updateFamily = async (userId, values) => {
  const familyRef = doc(newFirestore, 'users', userId)
  await updateDoc(familyRef, {
    family: values,
  })
}

export const updateAdult2 = async (userId, values) => {
  const familyRef = doc(newFirestore, 'users', userId)
  await updateDoc(familyRef, {
    family: { adult2: values },
  })
}

export const updateChild1 = async (userId, values) => {
  const familyRef = doc(newFirestore, 'users', userId)
  await updateDoc(familyRef, {
    family: { child1: values },
  })
}

export const updateChild2 = async (userId, values) => {
  const familyRef = doc(newFirestore, 'users', userId)
  await updateDoc(familyRef, {
    family: { child2: values },
  })
}

export const updateUserEmail = (newEmail) => {
  const auth = getAuth()
  updateEmail(auth.currentUser, newEmail)
    .then(() => {
      toast.success('Email successfully updated')
    })
    .catch((error) => {
      toast.error('Failed to update, please try again.', error.message)
    })
}
