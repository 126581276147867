import { Box, Typography, Divider, Button } from '@mui/material'
import Page from '../../../components/page/Page'
import Span from '../../../components/Span'
import ClassCard from '../components/ClassCard'
import useClasses from '../useClasses'
import NoClass from '../components/NoClass'
import { useEffect, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { useLocation } from 'react-router-dom'

const Classes = ({ session = false }) => {
  const { classes, error, loading } = useClasses(false, false, false, session)
  const [search, setSearch] = useState([])
  const location = useLocation()

  const filteredClasses = classes.filter((c) =>
    search.includes(c.startTime.toDate().getDay())
  )
  const displayClasses = search.length > 0 ? filteredClasses : classes

  console.log('filteredClasses', filteredClasses)

  const days = [
    { day: 'Monday', value: 1 },
    { day: 'Tuesday', value: 2 },
    { day: 'Wednesday', value: 3 },
    { day: 'Thursday', value: 4 },
    { day: 'Friday', value: 5 },
    { day: 'Saturday', value: 6 },
    { day: 'Sunday', value: 0 },
  ]

  const handleFilter = (day) => {
    search.includes(day)
      ? setSearch((pv) => pv.filter((d) => d !== day))
      : setSearch((pv) => [...pv, day])
  }

  useEffect(() => {
    setSearch([])
  }, [location])

  return (
    <Page loading={loading} error={error}>
      <Box>
        <Typography variant='h2' color='white.main'>
          <Span>{session ? 'Gym' : 'Class'}</Span>
        </Typography>
        <Typography variant='h2' color='white.main'>
          Schedule.
        </Typography>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          mt={2}
          alignItems={'center'}
        >
          <Typography color='white.main' variant='h5'>
            Filter
          </Typography>
          <Button
            startIcon={<ClearIcon />}
            color='secondary'
            variant='contained'
            disabled={search.length === 0}
            size='small'
            onClick={() => setSearch([])}
          >
            Clear filter
          </Button>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box display={'flex'} gap={0.5} sx={{ flexWrap: 'wrap' }}>
          {days.map((day) => (
            <Button
              key={day.value}
              size='small'
              onClick={() => handleFilter(day.value)}
              variant={search.includes(day.value) ? 'contained' : 'outlined'}
              sx={{ flexGrow: 1 }}
            >
              {day.day}
            </Button>
          ))}
        </Box>

        <Box
          mt={2}
          display={'grid'}
          gridTemplateColumns='repeat(auto-fit, minmax(300px, 1fr)) '
          gap={2}
        >
          {classes.length === 0 ? (
            <NoClass />
          ) : (
            displayClasses.map((c) => <ClassCard gymClass={c} key={c.id} />)
          )}
        </Box>
      </Box>
    </Page>
  )
}

export default Classes
