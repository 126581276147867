import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { firestore } from '../../../../../utils/firebase/firebase'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'

const PersonalDetailsSection = ({ user }) => {
  const [clickedChangeDetails, setClickedChangeDetails] = useState(false)
  const [clickedChangeBrand, setClickedChangeBrand] = useState(false)
  const [newBrand, setNewBrand] = useState(user.brand)
  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    mobile: user.mobile,
    credits: user.credits,
  })
  const userRef = firestore.collection('users').doc(user.id)
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (clickedChangeDetails) {
      setClickedChangeDetails(false)
      try {
        await userRef
          .update({
            ...formData,
            credits: parseInt(formData.credits),
          })
          .then(() => {
            toast.success('User details updated')
          })
      } catch (error) {
        toast.error('Error updating user details')
      }
      return
    }
    setClickedChangeDetails(true)
    setFormData({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      mobile: user.mobile,
      credits: user.credits,
    })
  }

  const togglePt = async () => {
    try {
      await userRef
        .update({
          isPt: !user.isPt,
        })
        .then(() => {
          toast.success('User updated successfully')
        })
    } catch (error) {
      toast.error('Error updating user details')
    }
  }

  const updateBrand = async (e) => {
    e.preventDefault()
    setClickedChangeBrand((prevState) => !prevState)

    if (clickedChangeBrand) {
      try {
        await userRef
          .update({
            brand: newBrand,
          })
          .then(() => {
            toast.success('User updated successfully')
          })
      } catch (error) {
        toast.error('Error updating user details')
      }
      return
    }
  }

  return (
    <Stack>
      <form onSubmit={handleSubmit}>
        <Box display={'flex'} justifyContent='space-between'>
          <Typography variant='h5' sx={{ color: 'white!important' }}>
            Personal details
          </Typography>
          <Box display={'flex'} gap={1}>
            <Button variant='contained' type='submit'>
              {clickedChangeDetails ? 'Submit' : 'Update'}
            </Button>
            {clickedChangeDetails && (
              <Button
                variant='outlined'
                onClick={() => setClickedChangeDetails(false)}
              >
                Cancel
              </Button>
            )}
          </Box>
        </Box>
        <Stack sx={{ mt: 2 }} gap={1.5}>
          <TextField
            id='firstName'
            label='First name '
            value={clickedChangeDetails ? formData.firstName : user.firstName}
            disabled={!clickedChangeDetails}
            onChange={onChange}
          />
          <TextField
            id='lastName'
            label='Surname '
            value={clickedChangeDetails ? formData.lastName : user.lastName}
            disabled={!clickedChangeDetails}
            onChange={onChange}
          />
          <TextField
            id='email'
            label='Email '
            value={clickedChangeDetails ? formData.email : user.email}
            disabled={true}
            onChange={onChange}
          />
          <TextField
            id='mobile'
            label='Mobile'
            value={clickedChangeDetails ? formData.mobile : user.mobile}
            disabled={!clickedChangeDetails}
            onChange={onChange}
          />

          <TextField
            id='DOB'
            label='DOB'
            value={dayjs(user.dob).format('DD MMM YYYY') ?? 'unavailable'}
            disabled={true}
          />
          <TextField
            id='plan'
            label='Plan'
            value={user.memberType}
            disabled={true}
          />
          <TextField
            id='status'
            label='status'
            value={user.status}
            disabled={true}
          />
        </Stack>
      </form>
      <Stack gap={1}>
        <Typography variant='h6' sx={{ color: 'white!important' }} mt={3}>
          Personal Trainer
        </Typography>
        <FormControlLabel
          control={
            <Switch checked={user.isPt} onChange={() => togglePt()} name='No' />
          }
          label='Yes - Declare this user a PT to make them available as a class host'
        />
        {user.isPt && (
          <Box
            display={'flex'}
            gap={1}
            component={'form'}
            onSubmit={updateBrand}
          >
            <TextField
              label='Optionally add a custom brand name '
              fullWidth
              size='small'
              value={clickedChangeBrand ? newBrand : user.brand}
              onChange={(e) => setNewBrand(e.target.value)}
              disabled={!clickedChangeBrand}
            />
            <Button variant='contained' type='submit'>
              {clickedChangeBrand ? 'Submit' : 'Update'}
            </Button>
            {clickedChangeBrand && (
              <Button
                variant='outlined'
                onClick={() => setClickedChangeBrand(false)}
              >
                Cancel
              </Button>
            )}
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

export default PersonalDetailsSection
