import React, { useState } from 'react'
import Modal from '../../../components/Modal'
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material'
import dayjs from 'dayjs'
import Span from '../../../components/Span'
import { connect } from 'react-redux'
import { friendlyCurrentUser, removeUserFromClass } from '../../classes/helpers'
import { toast } from 'react-toastify'

const ConfirmCanxClassModal = ({
  isOpen,
  onClose,
  gymClass,
  currentUser,
  user,
  isEnrolled,
}) => {
  const [error, setError] = useState()
  const handleCancelBooking = () => {
    try {
      removeUserFromClass(isEnrolled, friendlyCurrentUser(user), gymClass)
      onClose()
      toast.success('Success. You cancelled your booking')
    } catch (error) {
      setError(
        'Sorry, there was a problem cancelling your class. Please try again.'
      )
    }
  }

  return (
    <Modal title={'Are you sure?'} onClose={onClose} isOpen={isOpen}>
      <Typography>
        Are you sure you want to cancel {user.firstName}'s booking for{' '}
        <Span>{gymClass.class.title}</Span> on{' '}
        <Span>{dayjs(gymClass.startTime.toDate()).format('ddd DD MMM')}</Span>{' '}
        at <Span>{dayjs(gymClass.startTime.toDate()).format('h:mm A')}</Span>?
      </Typography>
      {isEnrolled ? (
        <Typography>
          If you change your mind you will need to regsiter for this class again
          and join a waitlist if it's full.
        </Typography>
      ) : (
        <Typography>
          You will lose your place in the waitlist and have to register again if
          you change your mind.
        </Typography>
      )}
      <Box mt={2} display={'flex'} gap={1}>
        <Button variant='contained' color='error' onClick={handleCancelBooking}>
          Cancel booking
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Exit
        </Button>
      </Box>
      {error && (
        <Alert severity='error' sx={{ mt: 2 }}>
          <AlertTitle>Error</AlertTitle>
          <Typography>{error} Please try again.</Typography>
        </Alert>
      )}
    </Modal>
  )
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
})

export default connect(mapStateToProps, null)(ConfirmCanxClassModal)
